import { Component } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Divider } from '@mui/material';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';

import RightArrowDark from 'themes/icons/rightArrowDark.svg';
import Dollar from 'themes/icons/dollar.png';
import PayCard from 'themes/icons/payCard.png';
import styles from './styles.module.css';
import { MODEL_TRANSITION_DELAY } from 'config/env';
import http from 'utilities/http';
import CouponInput from 'components/CouponInput/CouponInput';
import PaymentIntegration from 'utilities/payment';
import { cleverTapEvent } from 'utilities/analytics';

// const transactions = { NORMAL: 1, UPGRADE: 2, RENEW: 3 };

export default class TopUpModel extends Component {
  constructor(props) {
    super(props);
    // const { classes } = this.props;
    this.state = {
      action: 'topUp',
      sessionType: null,
      menuIndex: null,
      anchorEl: null,
      sessions: props?.sessions,
      sessionsLeft: null,
      price: 0,
      value: null,
      quantity: 1,
      seqNo: 0,
      noOfMonths: 0,
      couponDiscountedPrice: 0,
      couponCode: '',
      amount: 0,
      coins: 0,
      type: 0,
      initiatePayment: false,
      thanksup: false,
      total: 0,
      organizationID: null,
      totalCoins: 0,
      subName: props?.subName,
      open: false,
      coinsConversion: null,
      disableradio: true,
    };
    this.renderViewBasedOnAction = this.renderViewBasedOnAction.bind(this);
    this.renderTopUp = this.renderTopUp.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onInputchange = this.onInputchange.bind(this);
    this.handleCoins = this.handleCoins.bind(this);
    this.radioChecked = this.radioChecked.bind(this);
    this.topupConfirm = this.topupConfirm.bind(this);
    this.decrementCoins = this.decrementCoins.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.objectApi = this.objectApi.bind(this);
    this.renderCoins = this.renderCoins.bind(this);

    this.handleIncludeOrganization = this.handleIncludeOrganization.bind(this);
  }

  async componentDidMount() {
    await this.handleIncludeOrganization();
    this.objectApi();
  }
  renderCoins(price) {
    let pricebool;
    if (price !== 0) {
      if (this.state.totalCoins < Math.ceil(price / this?.state.coinsConversion)) {
        pricebool = true;
      } else {
        pricebool = false;
      }
    } else {
      pricebool = true;
    }
    this.setState({ disableradio: pricebool });
  }
  renderViewBasedOnAction() {
    if (this.state.action === 'topUp' || this.state.action === 'confirmation') {
      return this.renderTopUp();
    }
  }
  handleIncludeOrganization() {
    http.get('authentication/usersessiondetails').then((response) => {
      if (![null, undefined, ''].includes(response.data)) {
        this.setState({ organizationID: response.data.organizationID });
        this.setState({ totalCoins: response.data.userStats.totalCoins });
        this.handleCoins();
      }
    });
  }
  handleCoins() {
    http.get(`subscription/user_subscription/usage`).then((response) => {
      let usageName = [];

      if (response?.data?.liveInventory) {
        usageName.push({
          planName: 'LIVE',
        });
      } else {
        usageName.push({
          planName: 'PT',
        });
      }
      this.setState({ sessionsLeft: { usageName, ...response.data } });
    });
  }
  handleClose() {
    if (this.state.action === 'confirmation') {
      this.setState({ action: 'topUp' });
    } else {
      this.props?.handleClose();
    }
  }

  CleverTapTopup() {
    cleverTapEvent('Add To Cart', {
      Plan_Type: this.state.action + '- ₹' + this.state.price * this.state.quantity + '-' + this.state.value,
      price: this.state.price * this.state.quantity,
    });
  }
  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleOpen() {
    this.setState({ open: !this.state.open });
  }
  radioChecked(paramsValue, type) {
    if (type === 1) {
      this.setState({
        amount: paramsValue,
      });
      this.setState({
        coins: 0,
      });
      this.setState({ total: paramsValue, type: type });
    } else {
      this.setState({
        coins: paramsValue,
      });
      this.setState({
        amount: 0,
      });
      this.setState({ total: paramsValue, type: type });
    }
  }
  topupConfirm() {
    this.setState({ initiatePayment: true });
    sessionStorage.setItem('topup', 1);
  }
  increment(quaninty) {
    this.setState({ quantity: quaninty + 1 });
  }
  decrement(quaninty) {
    this.setState({ quantity: quaninty - 1 });
  }
  decrementCoins() {
    let checkedvalue;

    if (this.state.value !== 'PT') {
      checkedvalue = 'addlivetopup';
    } else {
      checkedvalue = 'addpttopup';
    }

    http
      .post(`subscription/${checkedvalue}`, {
        sessionCount: this.state.quantity,
      })
      .then((response) => {
        if (response) {
          this.props?.callThanks();
        }
      });
    this.CleverTapTopup();
  }
  objectApi() {
    const baseThis = this;
    http.get(`system/object`, { params: { objectID: 'CONVERSION' } }).then(function (res) {
      if (![null, undefined, ''].includes(res.data)) {
        baseThis.setState({ coinsConversion: res?.data?.coinExchangeValueInRupees });
      }
    });
  }
  renderTopUp() {
    return (
      <div>
        <Modal
          open={this.props?.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={styles.modalBackground}
          BackdropProps={{
            timeout: MODEL_TRANSITION_DELAY,
          }}
          closeAfterTransition
        >
          {/* <Box in={true}> */}
          <div className={styles.sessionModal}>
            <div className={styles.modalTitle}>
              <img src={RightArrowDark} alt="" onClick={() => this.handleClose()} className={styles.modelClose} />
              {this.state.action === 'topUp' && (
                <>
                  <span className={styles.modalTitleText}>TOP-UP</span>
                  <div className={styles.coinContainer}>
                    <img src={Dollar} alt="" className={styles.dollarImg} />
                    <p className={styles.coinBalance}>{this.state.totalCoins}</p>
                  </div>
                </>
              )}
              {this.state.action === 'confirmation' && (
                <>
                  <span className={styles.modalTitleText}>CONFIRM</span>
                </>
              )}
            </div>
            <div className={styles.mainContainer}>
              {this.state.action === 'topUp' && (
                <>
                  <div>
                    <div className={styles.optionChooseContainer}>
                      <span className={styles.selectSessionType}>Enter session type</span>
                      <div id="demo-customized-menu" style={{ width: '203px', background: '#F7FAF9' }}>
                        <Box>
                          <FormControl fullWidth>
                            {/* <InputLabel id="no-onfocus-test-select-label">Select-Type</InputLabel> */}
                            <Select
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              open={this.state.open}
                              onClose={() => this.handleOpen()}
                              onOpen={() => this.handleOpen()}
                              displayEmpty
                              // value=""
                              inputProps={{ 'aria-label': 'Without label' }}
                              value={this.state.value !== null ? this.state.value : ''}
                              onChange={(event) => {
                                this.setState({ value: event.target.value });
                              }}
                              className={this.state.value !== null && styles.selectedarrow}
                            >
                              <MenuItem disabled value="">
                                Select-Type
                              </MenuItem>
                              {this.state.sessions?.planActivities?.map(
                                (item, i) =>
                                  item?.activityID !== 'DIY' &&
                                  ![null, undefined, ''].includes(this.state.sessionsLeft) && (
                                    <MenuItem
                                      key={i}
                                      value={item?.activityID}
                                      onClick={() => {
                                        if (!isNaN(item?.topUpRatePerSession)) {
                                          this.setState({
                                            price: item?.topUpRatePerSession,
                                            seqNo: this.state.sessions.planSeqNo,
                                            noOfMonths: this.state.sessions.noOfMonths,
                                          });
                                        } else {
                                          this.setState({
                                            price: 0, //item?.planActivities[i].topUpRatePerSession,
                                            seqNo: this.state.sessions.planSeqNo,
                                            noOfMonths: this.state.sessions.noOfMonths,
                                          });
                                        }
                                        this.renderCoins(item?.topUpRatePerSession);
                                      }}
                                    >
                                      <span>
                                        {item?.activityID}
                                        <br />
                                        <>
                                          {item?.activityID === 'LIVE' &&
                                            ![null, undefined, ''].includes(this.state.sessionsLeft?.liveInventory) && (
                                              <>
                                                <span
                                                  className={styles.slotSessionLeft}
                                                  style={{ color: '#DF002C', marginBottom: '12px' }}
                                                >
                                                  {this.state.sessionsLeft?.topupInventory
                                                    ? this.state.sessionsLeft?.liveInventory?.TotalSessions -
                                                      this.state.sessionsLeft?.liveInventory?.usedSessions +
                                                      this.state.sessionsLeft?.topupInventory?.balLiveSessions
                                                    : this.state.sessionsLeft?.liveInventory?.TotalSessions -
                                                      this.state.sessionsLeft?.liveInventory?.usedSessions}{' '}
                                                  {''}
                                                  sessions left
                                                </span>
                                              </>
                                            )}
                                          {item?.activityID === 'PT' &&
                                            ![null, undefined, ''].includes(this.state.sessionsLeft?.PTInventory) && (
                                              <>
                                                <span
                                                  className={styles.slotSessionLeft}
                                                  style={{ color: '#DF002C', marginBottom: '12px' }}
                                                >
                                                  {this.state.sessionsLeft?.topupInventory
                                                    ? this.state.sessionsLeft?.PTInventory?.TotalSessions -
                                                      this.state.sessionsLeft?.PTInventory?.usedSessions +
                                                      this.state.sessionsLeft?.topupInventory?.balPTSessions
                                                    : this.state.sessionsLeft?.PTInventory?.TotalSessions -
                                                      this.state.sessionsLeft?.PTInventory?.usedSessions}{' '}
                                                  sessions left
                                                </span>
                                              </>
                                            )}
                                        </>
                                      </span>
                                    </MenuItem>
                                  )
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    </div>

                    <div className={styles.optionChooseContainer}>
                      <span className={styles.selectSessionType}>Enter number of sessions</span>
                      <div style={{ height: '44px', width: '116px' }}>
                        <div>
                          <div className={styles.numberInput}>
                            {this.state.quantity <= 1 ? (
                              ''
                            ) : (
                              <div
                                id="inputpointer"
                                style={{ cursor: 'pointer' }}
                                // onClick={() => document.querySelector('input[type=number]').stepDown()}
                                onClick={() => this.decrement(this.state.quantity)}
                              >
                                <RemoveIcon />
                              </div>
                            )}

                            <input
                              className={styles.quantity}
                              min="1"
                              name="quantity"
                              value={this.state.quantity}
                              onChange={(e) => console.log(e.target.value)}
                              max="99"
                              type="number"
                            ></input>
                            <div
                              id="inputpointer"
                              style={{ marginLeft: '0px', cursor: 'pointer' }}
                              // onClick={() => {document.querySelector('input[type=number]').stepUp()}}
                              onClick={() => this.increment(this.state.quantity)}
                            >
                              <AddIcon sx={{ color: '#DF002C' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {![null, undefined, 0].includes(this?.state?.price) && !isNaN(this?.state?.price) && (
                        <span className={styles.sessionHelpText}>
                          1 class = {Math.ceil(this?.state?.price / this?.state.coinsConversion)} RCB coins/₹{' '}
                          {this?.state?.price}
                        </span>
                      )}
                    </div>
                  </div>
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      marginBottom: '120px',
                    }}
                  >
                    <span className={styles.payText}>Pay Using</span>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        pointerEvents: this.state.disableradio && 'none',
                        opacity: this.state.disableradio && '0.4',
                      }}
                      onClick={() => {
                        !this.state.disableradio &&
                          this.radioChecked(Math.ceil(this?.state?.price / this?.state.coinsConversion), 2);
                      }}
                    >
                      <Radio
                        // className={styles.radiobutton}
                        sx={{
                          marginTop: '2px',
                          paddingLeft: '1px',
                          '&:hover': {
                            backgroundColor: 'rgb(250 253 255 / 54%) !important',
                          },
                        }}
                        value={Math.ceil(this?.state?.price / this?.state.coinsConversion)}
                        checked={this.state.type === 2}
                        // && this.state.total === this.state.price / 50 ? 'checked' : null
                        // }
                        name="coins"
                        // inputProps={{ 'aria-label': 'A' }}
                        disabled={
                          this?.state?.price === 0 ||
                          this.state.totalCoins < Math.ceil(this?.state?.price / this?.state.coinsConversion)
                            ? 'disabled'
                            : null
                        }
                      />
                      <img src={Dollar} alt="" className={styles.dollarImgInSection} />
                      <span className={styles.coinText}>RCB Coins</span>
                      <span className={styles.coinAmount}>
                        {this?.state?.coinsConversion
                          ? Math.ceil(this?.state?.price / this?.state?.coinsConversion) * this?.state?.quantity
                          : 0}
                      </span>
                    </div>
                    <div
                      style={{ display: 'flex', flexDirection: 'row', opacity: this?.state?.price === 0 && '0.4' }}
                      onClick={() => {
                        this?.state?.price !== 0 && this.radioChecked(this?.state?.price, 1);
                      }}
                    >
                      <Radio
                        sx={{
                          marginTop: '2px',
                          paddingLeft: '1px',
                          '&:hover': {
                            backgroundColor: 'rgb(250 253 255 / 54%) !important',
                          },
                        }}
                        value={![null, undefined, NaN].includes(this?.state?.price) && this.state.price}
                        name="amount"
                        checked={this.state.type === 1}
                        // checked={this.state.total !== 0 && this.state.total === this.state.price ? 'checked' : null}

                        inputProps={{ 'aria-label': 'A' }}
                        disabled={this?.state?.price === 0 && 'disabled'}
                      />
                      <img src={PayCard} alt="" className={styles.payCardInSection} />
                      <span className={styles.coinText}>Card/UPI/Net banking</span>
                      {
                        // this.state.sessions !== null && this.state.sessions.map((item, i) => {
                        //   item.planActivities[i].activityID === this.state.value ?

                        ![null, undefined, NaN].includes(this?.state?.price) && (
                          <span className={styles.coinAmount}>₹ {this?.state?.price * this?.state?.quantity}</span>
                        )

                        // <span className={styles.coinAmount}>₹ 1000</span>
                        // })
                      }
                    </div>
                  </div>
                  {(this.state.value !== null && this.state.amount !== 0) || this.state.coins !== 0 ? (
                    <div
                      style={{ width: '95%', marginLeft: '8px', marginRight: '8px', height: '43px' }}
                      className={styles.btn}
                      onClick={() => {
                        if (this.state.coins !== 0) {
                          this.decrementCoins();
                          this.CleverTapTopup();
                        } else {
                          this.setState({ action: 'confirmation' });
                          this.CleverTapTopup();
                        }
                      }}
                    >
                      <Button sx={{ height: '43px' }} fullWidth className={styles.btnText}>
                        <span>TOP-UP</span>
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{ width: '95%', marginLeft: '8px', marginRight: '8px', height: '43px' }}
                      className={styles.disablebtn}
                    >
                      <Button sx={{ height: '43px' }} fullWidth className={styles.btnText}>
                        <span>TOP-UP</span>
                      </Button>
                    </div>
                  )}
                </>
              )}

              {this.state.action === 'confirmation' && (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <div style={{ padding: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }} className={styles.topUpBaner}>
                      <div className={styles.topUpChip}>
                        <span className={styles.topUpChipText}>TOPUP</span>
                      </div>
                      <div style={{ display: 'flex', marginLeft: '17px', marginRight: '17px' }}>
                        <span className={styles.trainingName}>{this.state.value}</span>
                        <span className={styles.sessionSelected}>{this.state.quantity} Sessions</span>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', marginTop: 'auto' }}>
                    <div className={styles.totalArea}>
                      {/* <CouponInput
                        transactionType={transactions.NORMAL}
                        setDiscountedPrice={(price) => this.setState({ couponDiscountedPrice: price })}
                        productPrice={this.state.price * this.state.quantity}
                        productDefaultDiscount={0}
                        planName={'topup'}
                        planNoOfMonths={1}
                        planSeqNo={1}
                        setCouponCode={(code) => this.setState({ couponCode: code })}
                      /> */}
                      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
                        <span className={styles.subTotalText}>Sub Total</span>
                        <span className={styles.subTotal}>₹ {this.state.price * this.state.quantity}</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span className={styles.subTotalText}>(This amount is inclusive of tax)</span>
                        {/* <span className={styles.subTotal}>₹ {this.state.taxAmt}</span> */}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={styles.totalAmount}>
                      <span className={styles.totalText}>Sub Total</span>
                      <span className={styles.total}>₹ {this.state.price * this.state.quantity}</span>
                    </div>
                    {!window?.ReactNativeWebView?.postMessage && (
                      <div
                        style={{ width: '95%', marginLeft: '8px', marginRight: '8px' }}
                        className={styles.btn + ' ' + styles.btnWithMargin}
                        onClick={() => this.setState({ action: 'confirmation' })}
                      >
                        <Button
                          style={{ width: '100%' }}
                          fullWidth
                          className={styles.btnText}
                          onClick={() => this.topupConfirm()}
                        >
                          <span>TOP-UP</span>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* </Box> */}
        </Modal>
        {this.state.initiatePayment && (
          <PaymentIntegration
            closePayment={() => this.setState({ initiatePayment: false })}
            closeParentModal={this.props.handleClose}
            price={this.state.price * this.state.quantity}
            orderType={4}
            samelocation={window.location.pathname}
            subscriptionInfo={{
              planSeqNo: this.state.seqNo,
              activityType: this.state.value,
              sessionCount: this.state.quantity,
              noOfMonths: this.state.noOfMonths,
            }}
            text="topup"
            planselectedname={this.state?.sessions?.planName}
            popupClose={() => this.setState({ initiatePayment: false })}
            cleverTapInfo={{
              price: this.state.price * this.state.quantity,
              planName: this.state?.sessions?.planName,
              planType: 'topup',
              couponCode: 'NA',
            }}
          />
        )}
      </div>
    );
  }

  render() {
    return this.renderViewBasedOnAction();
  }
}
// export default withStyles(useStyles)(TopUpModel);
