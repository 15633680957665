import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import styles from './styles.module.css';
import { MdWifiOff } from 'components/Icons/MdWifiOff';

const NetworkConnectivity = () => {
  const [status, setStatus] = useState(() => {
    if (navigator.onLine) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      setStatus(true);
    };
    window.onoffline = (e) => {
      setStatus(false);
    };
  }, [status]);
  return (
    <div>
      {!status && (
        <>
          <div>
            <Modal open={true} hideBackdrop={true} aria-describedby="modal-modal-description" className={styles.netmodalBackground}>
              <div className={styles.customToastContainer}>
                <MdWifiOff size='20px' color='red' />
                <p>Oops! Network Disconnected</p>
              </div>
              {/* <div className={styles.userValidateModal}>
                <div className={styles.UserValidateModalContent}>
                  <img src={Assets.Images.NoSessionAvailable} alt="" className={styles.NoSessionAvailable} />
                  <pre className={styles.createAccountSuggestion}>Oops! Network Disconnected.</pre>
                  <pre className={styles.createAccountSuggestion}>Please Check Your Internet Connection.</pre>
                </div>
              </div> */}
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};
export default NetworkConnectivity;
