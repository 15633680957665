import { cleverTapEvent } from './analytics';

const EVENT_PURCHASE_TYPE = {
  BASE_PRODUCT: 'BASE_PRODUCT',
  ADDON_PRODUCT: 'ADDON_PRODUCT',
};

export class CleverTapEvents {
  static getOverallPaymentAddonDetails(selectedAddons = []) {
    if (!selectedAddons.length) return {};

    const details = {
      Purchase_type: EVENT_PURCHASE_TYPE.BASE_PRODUCT,
    };
    selectedAddons.forEach((item, i) => {
      details[`Addon_${i + 1}`] = `${item.serviceName} | ${item.price}`;
    });
    return details;
  }

  static addonsOverallPaymentInitiated(selectedAddons = []) {
    selectedAddons.forEach((item) => {
      const eventDetails = {
        Purchase_type: EVENT_PURCHASE_TYPE.ADDON_PRODUCT,
        Plan_name: item.serviceName,
        price: item.price,
      };
      cleverTapEvent('Overall_Payment_initiated', eventDetails);
    });
  }

  static addonsOverallPaymentCompleted(selectedAddons = []) {
    selectedAddons.forEach((item) => {
      const eventDetails = {
        Purchase_type: EVENT_PURCHASE_TYPE.ADDON_PRODUCT,
        Plan_name: item.serviceName,
        price: item.price,
      };
      cleverTapEvent('Overall_Payment_completed', eventDetails);
    });
  }
}
