import styles from './ReferralTutorialStyle.module.css';
import { DottedLineVertical } from './DottedLineVertical';

const ReferralTutorial = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.numbers}>
          <div>
            <span>1</span>
          </div>
          <div>
            <span>2</span>
          </div>
          {/* <div>
            <span>3</span>
          </div> */}
        </div>
        <DottedLineVertical height="78px" width="5px" />
      </div>
      <div className={styles.rightSection}>
        <p>
          Send the referral code to
          <br />
          your friend.
        </p>
        <p>
          Once your friend registers,
          <br />
          you will immediately earn 150 points.
        </p>
        {/* <p>
          On completing the trial period, once the
          <br />
          first payment is deduced you will be
          <br />
          awarded 6 points
        </p> */}
      </div>
    </div>
  );
};

export default ReferralTutorial;
