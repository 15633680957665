import { toast } from 'react-toastify';

export default {
  showSuccess: (message, options) => {
    toast.success(message, options);
  },
  showError: (message, options) => {
    toast.error(message, options);
  },
  showInfo: (message, options) => {
    toast.info(message, options);
  },
  showWarning: (message, options) => {
    toast.warning(message, options);
  },
};
