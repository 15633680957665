export const HomeIcon = ({ variant = '' }) => {
  return (
    <>
      {variant === '' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.29297 9.44829L3.82391 8.86307L3.54297 9.08824V9.44829H4.29297ZM19.7083 9.44829H20.4583V9.08824L20.1774 8.86307L19.7083 9.44829ZM19.7083 19.9999V20.7499H20.4583V19.9999H19.7083ZM4.29297 19.9999H3.54297V20.7499H4.29297V19.9999ZM12.0007 3.27051L12.4697 2.68529L12.0007 2.30933L11.5316 2.68529L12.0007 3.27051ZM18.9583 9.44829V19.9999H20.4583V9.44829H18.9583ZM19.7083 19.2499H4.29297V20.7499H19.7083V19.2499ZM5.04297 19.9999V9.44829H3.54297V19.9999H5.04297ZM4.76203 10.0335L12.4697 3.85573L11.5316 2.68529L3.82391 8.86307L4.76203 10.0335ZM11.5316 3.85573L19.2393 10.0335L20.1774 8.86307L12.4697 2.68529L11.5316 3.85573Z"
            fill="#677572"
          />
          <path d="M12 15.9644L12 12.7178" stroke="#677572" strokeWidth="1.5" strokeLinecap="square" />
        </svg>
      )}
      {variant === 'hover' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.29248 9.44926L3.66707 8.66897L3.29248 8.9692V9.44926H4.29248ZM19.7078 9.44926H20.7078V8.9692L20.3333 8.66897L19.7078 9.44926ZM19.7078 20.0009V21.0009H20.7078V20.0009H19.7078ZM4.29248 20.0009H3.29248V21.0009H4.29248V20.0009ZM12.0002 3.27148L12.6256 2.49119L12.0002 1.98992L11.3747 2.49119L12.0002 3.27148ZM18.7078 9.44926V20.0009H20.7078V9.44926H18.7078ZM19.7078 19.0009H4.29248V21.0009H19.7078V19.0009ZM5.29248 20.0009V9.44926H3.29248V20.0009H5.29248ZM4.91789 10.2296L12.6256 4.05178L11.3747 2.49119L3.66707 8.66897L4.91789 10.2296ZM11.3747 4.05178L19.0824 10.2296L20.3333 8.66897L12.6256 2.49119L11.3747 4.05178Z"
            fill="#141C1A"
          />
          <path d="M12 15.9654L12 12.7188" stroke="#141C1A" strokeWidth="2" strokeLinecap="square" />
        </svg>
      )}
      {variant === 'active' && (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.94238 9.44829L4.31697 8.66799L3.94238 8.96823V9.44829H4.94238ZM20.3577 9.44829H21.3577V8.96823L20.9832 8.66799L20.3577 9.44829ZM20.3577 19.9999V20.9999H21.3577V19.9999H20.3577ZM4.94238 19.9999H3.94238V20.9999H4.94238V19.9999ZM12.6501 3.27051L13.2755 2.49021L12.6501 1.98894L12.0247 2.49021L12.6501 3.27051ZM19.3577 9.44829V19.9999H21.3577V9.44829H19.3577ZM20.3577 18.9999H4.94238V20.9999H20.3577V18.9999ZM5.94238 19.9999V9.44829H3.94238V19.9999H5.94238ZM5.5678 10.2286L13.2755 4.0508L12.0247 2.49021L4.31697 8.66799L5.5678 10.2286ZM12.0247 4.0508L19.7323 10.2286L20.9832 8.66799L13.2755 2.49021L12.0247 4.0508Z"
            fill="#141C1A"
          />
          <path d="M12.6504 15.9644L12.6504 12.7178" stroke="#DF002C" strokeWidth="2" strokeLinecap="square" />
        </svg>
      )}
    </>
  );
};
