import React from "react";

export const DottedLineVertical = ({width = '3px', height = '100px', color = 'rgb(20, 28, 26)'}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 2 120"
      fill='none'
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.75"
        y1="3.27835e-08"
        x2="0.749995"
        y2="120"
        stroke={color}
        strokeOpacity="0.1"
        strokeWidth="1.5"
        strokeDasharray="2 2"
      />
    </svg>
  );
};
