import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from 'context';

import BigComing from 'themes/icons/bigcoming.svg';
import Maintainence from 'themes/icons/maintaince.svg';
import WentWrong from 'themes/icons/wentwrong.svg';
import styles from './styles.module.css';
import { useParams, useNavigate } from 'react-router-dom';

const ErrorScreens = (paramid) => {
  const configContext = useContext(ConfigContext);
  configContext.setAuth(true);
  configContext.setHeaderTheme('light');
  const getparam = useParams();
  let navigate = useNavigate();

  const [paramId, setParamId] = useState(paramid.paramid);

  useEffect(() => {
    if (![null, undefined, ''].includes(paramid.paramid)) {
      setParamId(paramid.paramid);
    }
    if (![null, undefined, ''].includes(getparam.id)) {
      setParamId(getparam.id);
    }
  }, [paramid.paramid, getparam]);

  return (
    <div className={styles.paramdiv}>
      {paramId == 1 && (
        <div className={styles.maindiv}>
          <img src={BigComing} alt="" className={styles.Img} />
          <p className={styles.comingText}>Something Big is Coming Your Way</p>
          <p className={styles.subText}>Something exceptional for everyone. With wide variety of fitness classes.</p>
        </div>
      )}
      {paramId == 2 && (
        <div className={styles.maindiv}>
          <img src={Maintainence} alt="" className={styles.Img} />
          <p className={styles.comingText}>Site Under maintenance</p>
          <p className={styles.subText}>We are updating the Hustle Platform for you. Please check back soon</p>
        </div>
      )}
      {paramId == 3 && (
        <div className={styles.maindiv}>
          <img src={WentWrong} alt="" className={styles.Img} />
          <p className={styles.comingText}>Something went wrong</p>
          <p className={styles.subText}>
            We can’t find that page, but you can always chat with our expert at Hustle. if you have a question or{' '}
            <strong className={styles.need}>need support.</strong>
          </p>
        </div>
      )}
      {paramId == 4 && (
        <div className={styles.maindiv}>
          <img src={BigComing} alt="" className={styles.Img} />
          <p className={styles.comingText}>Coming Soon</p>
          <p className={styles.subText}>
            Nutrition plans to complement your fitness journey, DNA tests to understand your body better and more…
          </p>
        </div>
      )}
      {paramId == 5 && (
        <div className={`${styles.maindiv} ${styles.mainArea}`}>
          <img src={WentWrong} alt="" className={styles.Img} />
          <p className={styles.comingText}>Something went wrong</p>
          <p className={styles.subText}>Please try again later</p>
          <div
              className={styles.homepageButton}              
              onClick={() => navigate('/home')}
            >
              Go To Home Page
            </div>
        </div>
      )}
      

    </div>
  );
};
export default ErrorScreens;
