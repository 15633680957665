import { createRef } from 'react';
import { io } from 'socket.io-client';

import { store } from 'store';
import { onConnect, onDisconnect, updateUser, removeUser, addMessages, addLastMessage } from 'store/reducers/chat';
import { SOCKET_KEY, SOCKET_URL } from 'config/env';

const socket = createRef();
const socketOpened = createRef();

const listeners = {
  connect: {},
};

export const createSocket = () => {
  if (!socket.current) {
    // const state = store.getState();
    socket.current = io(SOCKET_URL, {
      forceNew: true,
      autoConnect: false,
      transports: ['websocket'],
      path: '/io',
    });
    registerSocketEvents();
  }
};

export const deleteSocket = () => {
  unregisterSocketEvents();
  disconnectSocket();
};

export const connectSocket = () => {
  socketOpened.current = true;
  if (!socket.current?.connected) {
    const state = store.getState();
    if (state.session.isLoggedIn) {
      socket.current.io.opts.query = {
        appkey: SOCKET_KEY,
        token: state.session.token,
      };
      socket.current.connect();
    }
  }
};

export const disconnectSocket = () => {
  socketOpened.current = false;
  if (socket.current) {
    socket.current.disconnect();
  }
};

export const reconnectSocket = () => {
  socket.current.disconnect();
  socket.current.connect();
};

export const emitSocket = (name, params, cb = () => {}) => {
  socket.current.emit(name, params, cb);
};

export const asyncEmitSocket = (name, params, timeout = 5000) =>
  new Promise((resolve, reject) => {
    const t = setTimeout(() => reject(new Error('Request timed out')), timeout);
    socket.current.emit(name, params, (data) => {
      clearTimeout(t);
      resolve(data);
    });
  });

/* */

export const getSocket = () => socket.current;
export const checkSocketConnected = () => socket.current?.connected === true;
export const checkSocketOpened = () => socketOpened.current === true;

export const connectOrReconnectSocket = () => {
  if (socketOpened.current === true) {
    if (!socket.current?.connected) {
      reconnectSocket();
    }
  } else {
    connectSocket();
  }
};

/* */

const registerSocketEvents = () => {
  socket.current.on('connect', onSocketConnect);
  socket.current.on('disconnect', onSocketDisconnect);

  socket.current.io.on('error', (e) => console.log('io error', e));

  socket.current.on('error', (e) => console.log('error', e));
  socket.current.on('connect_error', (e) => console.log('connect_error', e));
  socket.current.on('reconnect', (attempt) => console.log('reconnect', attempt));
  socket.current.on('reconnect_attempt', (attempt) => console.log('reconnect_attempt', attempt));
  socket.current.on('reconnect_error', (e) => console.log('reconnect_error', e));
  socket.current.on('reconnect_failed', (e) => console.log('reconnect_failed', e));

  socket.current.on('presence', onEventPresence);
  socket.current.on('system', onEventSystem);
  socket.current.on('servermessage', onEventServerMessage);
};

const unregisterSocketEvents = () => {
  try {
    if (socket.current) {
      socket.current.off();
    }
    /* eslint-disable-next-line */
  } catch (e) {}
};

/* */
const onSocketConnect = () => {
  store.dispatch(onConnect());
  Object.keys(listeners.connect).forEach((k) => listeners.connect[k]());
};

const onSocketDisconnect = (reason) => {
  if (
    reason === 'io server disconnect' ||
    reason === 'ping timeout' ||
    reason === 'transport close' ||
    reason === 'transport error'
  ) {
    connectSocket();
  } else {
    store.dispatch(onDisconnect());
  }
};

/* */
const onEventPresence = (r) => {
  const state = store.getState();
};

const onEventSystem = (r) => {
  const state = store.getState();

  switch (r.type) {
    case 'NEW_REQUEST':
      // store.dispatch(updateUsers({ users: [r.data] }))
      break;
    case 'ACCEPT_PERSONAL_INVITE':
      store.dispatch(updateUser({ status: r.data.status, id: r.senderID }));
      break;
    case 'BLOCK_USER':
      if (r.receiverID == state.session.user.userID) {
        store.dispatch(updateUser({ status: r.data.status, id: r.senderID }));
      }
      break;
    case 'UNBLOCK_USER':
      if (r.senderID == state.session.user.userID) {
        store.dispatch(removeUser({ id: r.receiverID }));
      }
      break;
  }
};

const onEventServerMessage = async (res) => {
  const state = store.getState();

  switch (res.type) {
    case 'MESSAGE':
      if (res.data.context === 'PRIVATE') {
        await store.dispatch(addMessages({ messages: [res.data], userID: state.session.user.userID }));
        await store.dispatch(addLastMessage({ message: res.data, userID: state.session.user.userID }));
      }
      break;
  }
};

export const addSocketListeners = (name, key, cb) => {
  listeners[name][key] = cb;
};

export const removeSocketListeners = (name, key) => {
  if (typeof listeners[name][key] === 'function') {
    delete listeners[name][key];
  }
};

export default socket;
