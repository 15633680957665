export const SERVER_URL = 'https://api.hustle.royalchallengers.com/';
export const LIVE_SESSION_URL = 'https://live.hustle.royalchallengers.com/';
export const SOCKET_URL = 'https://messaging.hustle.royalchallengers.com/chat';
export const SOCKET_KEY =
  'gdSDFGsDSSFwekjasfvASDFGAsdknasfdASDFniasvbkjasdvsdASDF';
export const GOOGLE_ANALYTICS_KEY = 'UA-10618185-10';
export const SERVER_TYPE = 'PROD';
export const RCB_SSO_URL = 'https://api.royalchallengers.com/';
export const RCB_APP_KEY = 'a28a9ff5-19bc-4869-aad5-7cb268a2a6a9';
export const RCB_AUTH_KEY = '6c8945de-2dbd-4423-a2f3-ee4061978b73';
export const AES_KEY = 'Jht9ghZQkxbR8O1rr10lhhiJrgnEu3OF';
export const AES_IV = 'O8OpUHCgBkMHwNkI';
export const FITNESS_EXIT_CODE = 'ZXhpc3Rpbmd1c2VybG9nb3V0';
export const CLIENT_URL = 'https://trainee.hustle.royalchallengers.com/';
export const MARKETING_URL = 'https://www.royalchallengers.com/hustle';
export const LAMI_ACCESS_KEY = 'RCBDIAGEOLFTHUSTLE';
export const LAMI_SECRET_KEY = 'sabszzihnobirqvfrvnrhuhgyhkevcwx';
export const LAMI_API_URL = 'https://hustle.lami.fit/servers';
export const LAMI_PREFIX = 'HUSNUT';
export const RAZORPAY_KEY = 'rzp_live_qWteqBC4HimSnO';
export const MODEL_TRANSITION_DELAY = 350;
export const APP_TOKEN = 'ap8a9ff5-19bc-48n9-aad5-9cb26802a6a7';
export const LAMI_TRAILPERIOD_ENDDAYS = 10;
export const VITALFORM_AI_URL = 'https://formtrack.vitalform.io/#/';
export const ENABLE_AI_EXERCISE_ASSITANCE = false;
export const LAMI_TOKEN_PLACEHOLDER = 'OITHCIOvRdpDqIvCgusk';
export const FB_PIXEL_ID = '171381012407766';
export const CLTP_ID = '447-74R-986Z';
export const BLOG_URL = 'https://www.royalchallengers.com/blog';
export const RCB_SITE_URL = 'www.royalchallengers.com';
