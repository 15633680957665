import styles from './CardSwiper.module.css';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { Navigation } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
// import { ProgramCard2 } from 'components/Card/ProgramCard2';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide, } from 'swiper/react';

const SLIDE_STYLE = { padding: '0px', margin: '0px', border: 'none', background: 'none', cursor: 'pointer' };
function getWrapperClasses(itemIndex, activeIndex) {
  let classes = styles.cardWrapper;
  if (itemIndex === activeIndex) {
    classes = `${classes} ${styles.cardWrapper_active}`;
  } else {
    classes = `${classes} ${styles.cardWrapper_inactive}`;
  }
  return classes;
}

export const CardSwiper = ({ programs = [], initialSlide = 0, onClick, activeIndex, setActiveIndex }) => {
  const isMobile = useMediaQuery({ maxWidth: '768px' });
  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };
  return (
    <>
      {/* <Swiper
      onSlideChange={(swiper) => handleSlideChange(swiper)}
      slidesPerView={'auto'}
      spaceBetween={isMobile ? 10 : 20}
      centeredSlides={true}
      modules={[Navigation]}
      initialSlide={initialSlide}
      navigation={!isMobile}
      className={styles.fade}
    >
      {programs?.map((item, i) => (
        <SwiperSlide key={item.programSeqNo} style={SLIDE_STYLE} onClick={() => onClick(item)}>
          <div className={`${getWrapperClasses(i, activeIndex)}`}>
            <ProgramCard2
              imageUrl={item.coverImage}
              price={Number(item.price)}
              showPrice={activeIndex === i ? true : false}
              isActive={activeIndex === i ? true : false}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper> */}
    </>
  );
};
