import { createContext } from 'react';

const Config = createContext({
  isLoggedIn: false,
  setAuth: () => {},
  searchedTopic: '',
  changeSearchedTopic: () => {},
  headerTheme: 'light',
  setHeaderTheme: () => {},
  loginCredentials: '',
  setLoginCredentials: () => {},
  loginUserData: {},
  setLoginUserData: () => {},
  loginInputValidations: {},
  setLoginInputValidations: () => {},
  selectGoalData: [],
  setSelectGoalData: () => {},
  registrationCredentials: {},
  setRegistrationCredentials: () => {},
  base_url: '',
  basicInfoData: {},
  setBasicInfoData: () => {},
  subcriptionPlanName: [],
  setsubcriptionPlanName: () => {},
  subcriptionMobileDetails: [],
  setsubcriptionMobileDetails: () => {},
  calValue: {},
  setCalValue: () => {},

  durationValue: '',
  setDurationValue: () => {},
  difficultyValue: '',
  setDifficultyValue: () => {},
  equipmentsValue: '',
  setEquipmentsValue: () => {},
  userLoggedIn: '',
  setUserLoggedIn: () => {},
  showLeftMenu: '',
  setShowLeftMenu: () => {},
  plainLayout: '',
  setPlainLayout: () => {},

  userStats: '',
  setUserStats: () => {},
});

export default Config;
