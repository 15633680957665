import moment from 'moment';
import { cleverTapEvent } from './analytics';
import { CLIENT_URL, SERVER_TYPE } from 'config/env';
import {
  LANDING_PAGE_PROGRAMS,
  PROGRAM_CONFIGS,
  WEIGHT_AND_WATCH_SEQNO,
} from 'config/commonConfigs';

export function JSONMerge(source, data) {
  const sourceVal = Object.assign({}, source);
  const dataVal = Object.assign({}, data);
  if (
    ![null, undefined].includes(sourceVal) &&
    ![null, undefined].includes(dataVal)
  ) {
    for (const key in dataVal) {
      if (
        typeof sourceVal === 'object' &&
        sourceVal[key] &&
        typeof sourceVal[key] === 'object' &&
        !Array.isArray(sourceVal[key])
      ) {
        sourceVal[key] = this.JSONMerge(sourceVal[key], dataVal[key]);
      } else if (typeof sourceVal === 'object') {
        sourceVal[key] = dataVal[key];
      }
    }
  }

  return sourceVal;
}

export function isTruthy(value) {
  return ![null, undefined, ''].includes(value);
}

export function isFalsy(value) {
  return [null, undefined, ''].includes(value);
}

export function getFormattedPrice(price) {
  return Number(price)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
    .replace('₹', '₹ ');
}

export function updateHustleOpenEvent(username, email) {
  if (!username || !email) return;

  const now = moment();
  const lastEventDetails = JSON.parse(
    localStorage.getItem('lastHustleOpenTimestamp'),
  );

  if (lastEventDetails?.lastOpenTimeStamp && lastEventDetails?.email) {
    const lastDayDateObj = moment.unix(lastEventDetails.lastOpenTimeStamp);
    const isSameDay = lastDayDateObj.isSame(now, 'day');
    if (isSameDay && email === lastEventDetails.email) return;
  }

  console.log(username, email, 'event called');
  cleverTapEvent('Hustle-open', {
    Username: username,
    Email: email,
  });
  localStorage.setItem(
    'lastHustleOpenTimestamp',
    JSON.stringify({ lastOpenTimeStamp: now.unix(), email }),
  );
}

export function getRandomIndexFromArr(array = []) {
  if (!array?.length) return null;
  return array[Math.floor(Math.random() * array.length)];
}

export function openPrivacyPolicy() {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;
  const systemZoom = width / window.screen.availWidth;
  const left = (width - 500) / 2 / systemZoom + dualScreenLeft;
  const top = (height - 500) / 2 / systemZoom + dualScreenTop;
  window.open(
    CLIENT_URL + `userconsent`,
    `Terms`,
    `location=yes,height=${height},width=${width},scrollbars=yes,status=yes,top=${top} left=${left}`,
  );
}

export function openTerms() {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;
  const systemZoom = width / window.screen.availWidth;
  const left = (width - 500) / 2 / systemZoom + dualScreenLeft;
  const top = (height - 500) / 2 / systemZoom + dualScreenTop;
  window.open(
    CLIENT_URL + `terms-condition`,
    `Terms`,
    `location=yes,height=${height},width=${width},scrollbars=yes,status=yes,top=${top} left=${left}`,
  );
}

export function validatePassword(password = '') {
  const rules = {
    rule1: {
      isValid: /^.{12,}$/.test(password),
      message: 'The password should have a minimum length of 12 characters.',
    },
    rule2: {
      isValid: /^(?=.*[a-z])(?=.*[A-Z])/.test(password),
      message:
        'The password should contain at least one lowercase letter and one uppercase letter.',
    },
    rule3: {
      isValid: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password),
      message: 'The password should contain at least one special character.',
    },
  };

  return rules;
}

export function addSpecialProgramDisplayOrder(programs = []) {
  const seqNos = {
    sixpack: LANDING_PAGE_PROGRAMS.find(
      (itm) => itm.name === 'six-pack-project',
    ).seqNo[SERVER_TYPE],
    WAWC: WEIGHT_AND_WATCH_SEQNO[SERVER_TYPE],
    WWRP: LANDING_PAGE_PROGRAMS.find(
      (itm) => itm.name === 'workout-with-rcb-players',
    ).seqNo[SERVER_TYPE],
    build: PROGRAM_CONFIGS.BUILD.seqNo[SERVER_TYPE],
  };

  const sixpackProg = programs.filter(
    (itm) => itm.programSeqNo === seqNos.sixpack,
  );
  const wawcProg = programs.filter((itm) => itm.programSeqNo === seqNos.WAWC);
  const wwrpProg = programs.filter((itm) => itm.programSeqNo === seqNos.WWRP);
  const buildProg = programs.filter((itm) => itm.programSeqNo === seqNos.build);

  programs = programs.filter(
    (item) =>
      item.programSeqNo !== seqNos.sixpack &&
      item.programSeqNo !== seqNos.WAWC &&
      item.programSeqNo !== seqNos.WWRP &&
      item.programSeqNo !== seqNos.build,
  );
  programs = [
    ...buildProg,
    ...sixpackProg,
    ...wawcProg,
    ...wwrpProg,
    ...programs,
  ];
  return programs;
}

export function setSessionStorage(key, value) {
  window?.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionStorage(key) {
  return JSON.parse(window?.sessionStorage.getItem(key));
}

export function getLocalStorage(key) {
  return JSON.parse(window?.localStorage?.getItem(key));
}

export function setLocalStorage(key, value) {
  window?.localStorage.setItem(key, JSON.stringify(value));
}

//price: number, discountPercentage: number, limit?: number
export function getDiscountedPrice(price, discountPercentage, limit) {
  const discount = Math.round((price * discountPercentage) / 100);
  if (limit) {
    //with limit param
    if (discount < limit) {
      return price - discount;
    } else {
      return price - limit;
    }
  } else {
    // without limit param
    const newPrice = price - discount;
    if (newPrice > 0) {
      return newPrice;
    } else {
      return 0;
    }
  }
}

export function isEquipmentRequired(equipmentsArr) {
  if (!equipmentsArr || !equipmentsArr?.length) {
    return false;
  } else if (
    equipmentsArr?.length === 1 &&
    (equipmentsArr?.[0]?.name === 'No Equipments' ||
      equipmentsArr?.[0]?.name === 'No Equipment')
  ) {
    return false;
  } else {
    return true;
  }
}
