import { useEffect, useState, useContext } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'store';
import Route from 'navigation/route';
import { ConfigContext } from 'context';
import { createSocket, deleteSocket } from 'socket';
import { connect } from 'react-redux';

import { updateUser, logout, updateToken } from 'store/reducers/session';
import { initialize as initializeAnalytics } from 'utilities/analytics';
import http from '../utilities/http';
import { SERVER_URL } from 'config/env';
import 'themes/css/global.css';
import CacheBuster from 'CacheBuster';
import NetworkConnectivity from 'components/Modal/NetworkConnectivity';
import { RootLoader } from 'components/loaders/root-loader';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  setSessionStorage,
  updateHustleOpenEvent,
} from 'utilities/commonMethods';

const devices = {
  OTHERS: null,
  ANDRIOD: 1,
  IOS: 2,
};

const RootComponent = connect(null, { updateUser, logout, updateToken })(
  (props) => {
    const base_url = SERVER_URL;
    const [isLoggedIn, setAuth] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(
      ![null, undefined, 'undefined', ''].includes(
        localStorage.getItem('token'),
      )
        ? true
        : false,
    );
    const [showLeftMenu, setShowLeftMenu] = useState(
      ![null, undefined, 'undefined', ''].includes(
        localStorage.getItem('token'),
      )
        ? true
        : false,
    );
    const [plainLayout, setPlainLayout] = useState(false);
    const [headerTheme, setHeaderTheme] = useState('light');
    const [loginId, setLoiginId] = useState({});
    const [loginInputValidations, setLoginInputValidations] = useState({
      isEmailId: false,
      isMobileNumber: false,
      isValidPassWord: false,
      isValidOTP: false,
    });
    const [selectGoalData, setSelectGoalData] = useState([]);
    const [registrationCredentials, setRegistrationCredentials] = useState({});
    const patchUserData = {
      userName: '',
      emailId: '',
      mobileNumber: '',
      sessionProfilePic: '',
      firstName: '',
      lastName: '',
      userInfo: {},
      level: null,
      coins: null,
      points: 0,
    };

    useEffect(() => {
      if (
        Object.keys(props?.userInfo).length > 0 &&
        ![null, undefined, 'undefined', ''].includes(
          localStorage.getItem('token'),
        )
      ) {
        const token = localStorage.getItem('token');
        props?.updateToken({ token: token });
        props?.updateUser({ user: props?.userInfo });
      }
    }, [props?.userInfo]);

    if (
      [null, undefined, 'undefined', ''].includes(localStorage.getItem('token'))
    ) {
      props?.logout();
    }
    if (props?.userInfo) {
      patchUserData.userInfo = props?.userInfo;
      if (props?.userInfo?.profilePicture) {
        patchUserData.sessionProfilePic = props?.userInfo?.profilePicture;
      }
      if (props?.userInfo?.profile?.firstName) {
        patchUserData.firstName = props?.userInfo?.profile?.firstName;
      }
      if (props?.userInfo?.profilePicture) {
        patchUserData.lastName = props?.userInfo?.profile?.lastName;
      }
      patchUserData.level = props?.userInfo?.userStats?.level?.level;
      patchUserData.coins = props?.userInfo?.userStats?.totalCoins;
      patchUserData.points = props?.userInfo?.userStats?.totalPoints;
    }
    const [loginUserData, setLoginUserData] = useState(patchUserData);
    const [basicInfoData, setBasicInfoData] = useState({
      firstName: '',
      lastName: '',
      DOB: '',
      gender: '',
      profilePic: '',
      sessionProfilePic: '',
      location: '',
      department: '',
    });
    const [subcriptionPlanName, setsubcriptionPlanName] = useState([{}]);
    const [subcriptionMobileDetails, setsubcriptionMobileDetails] = useState([
      {},
    ]);
    const [calValue, setCalvalue] = useState({});
    const [durationValue, setDurationValue] = useState('');
    const [searchedTopic, setSearchedTopic] = useState(() => {
      if (
        ![null, undefined, ''].includes(localStorage.getItem('searchedTopic'))
      )
        return localStorage.getItem('searchedTopic');
      else return '';
    });
    const [difficultyValue, setDifficultyValue] = useState('');
    const [equipmentsValue, setEquipmentsValue] = useState('');
    const [userStats, setUserStats] = useState('');

    const changeSearchedTopic = (value) => {
      setSearchedTopic(value);
      localStorage.setItem('searchedTopic', value);
    };

    return (
      <ConfigContext.Provider
        value={{
          base_url,
          isLoggedIn,
          setAuth,
          headerTheme,
          setHeaderTheme,
          loginCredentials: loginId,
          setLoginCredentials: setLoiginId,
          loginUserData,
          setLoginUserData,
          loginInputValidations,
          setLoginInputValidations,
          selectGoalData,
          setSelectGoalData,
          registrationCredentials,
          setRegistrationCredentials,
          basicInfoData,
          setBasicInfoData,
          subcriptionPlanName,
          setsubcriptionPlanName,
          subcriptionMobileDetails,
          setsubcriptionMobileDetails,
          calValue,
          setCalvalue,
          durationValue,
          setDurationValue,
          searchedTopic,
          changeSearchedTopic,
          difficultyValue,
          setDifficultyValue,
          equipmentsValue,
          setEquipmentsValue,
          userLoggedIn,
          setUserLoggedIn,
          showLeftMenu,
          setShowLeftMenu,
          plainLayout,
          setPlainLayout,
          setUserStats,
          userStats,
        }}
      >
        <Route />
      </ConfigContext.Provider>
    );
  },
);

const App = () => {
  const [expired, setExpired] = useState(false);
  const configContext = useContext(ConfigContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    //handle redirection params --- start
    const allWorkoutSeqNo = Number(searchParams.get('allWorkout'));
    if (allWorkoutSeqNo > 0) {
      setSessionStorage('allWorkoutSeqNo', allWorkoutSeqNo);
    } else {
      sessionStorage.removeItem('allWorkoutSeqNo');
    }
    //handle redirection params ---end

    getUserInfo();
    createSocket();
    initializeAnalytics();
    checkDevice();
    return () => {
      deleteSocket();
    };
  }, []);

  function getUserInfo() {
    localStorage.setItem('appState', '0');
    localStorage.removeItem('locationChanging');
    if (
      ![null, undefined, 'undefined', ''].includes(
        localStorage.getItem('token'),
      )
    ) {
      http
        .get('authentication/usersessiondetails')
        .then((response) => {
          if (response && response?.data) {
            updateHustleOpenEvent(
              `${response?.data?.profile?.firstName} ${response?.data?.profile?.lastName}`,
              response.data?.account?.emailID,
            );

            setUserInfo(response?.data);
            if (
              [null, undefined, 'undefined', ''].includes(
                localStorage.getItem('specialprogramUser'),
              )
            ) {
              if (response?.data.profile.regStatus === 0) {
                if (
                  response?.data.profile.firstName == null ||
                  response?.data.profile.lastName == null ||
                  response?.data.profilePicture == null ||
                  response?.data.profile.gender == null ||
                  response?.data.profile.dateOfBirth == null ||
                  response?.data?.profile?.state?.ID === null
                ) {
                  localStorage.setItem('regStatus', 0);
                } else if (response?.data?.userStats?.mobileNoVerified === 0) {
                  localStorage.setItem('regStatus', 1);
                } else if (response?.data.account.mobileNO === null) {
                  localStorage.setItem('regStatus', 1);
                } else if (response?.data.profile.weight === null) {
                  localStorage.setItem('regStatus', 2);
                } else if (response?.data.goals === null) {
                  localStorage.setItem('regStatus', 3);
                } else if (response?.data.profile.experience === null) {
                  localStorage.setItem('regStatus', 4);
                }
              } else {
                localStorage.removeItem('regStatus');
              }
            } else {
              localStorage.removeItem('regStatus');
            }
          }
          setShow(true);
        })
        .catch((error) => {
          if (error?.response?.data?.errorCode === 'TOKEN_EXPECTED') {
            setExpired(true);
            localStorage.removeItem('token');
            configContext.setUserLoggedIn(false);
          } else if (error?.response?.data?.errorCode === 'INVALID_TOKEN') {
            setExpired(true);
            localStorage.removeItem('token');
            configContext.setUserLoggedIn(false);
          } else if (error?.response?.data?.errorCode === 'PERMISSION_DENIED') {
            setExpired(true);
            localStorage.removeItem('token');
            configContext.setUserLoggedIn(false);
          } else if (error?.response?.data?.errorCode === 'RECORD_NOT_FOUND') {
            setExpired(true);
            localStorage.removeItem('token');
            configContext.setUserLoggedIn(false);
          } else {
            setExpired(true);
            localStorage.removeItem('token');
            configContext.setUserLoggedIn(false);
          }
          setShow(true);
        });
    } else {
      localStorage.removeItem('token');
      configContext.setUserLoggedIn(false);
      setShow(true);
    }
  }
  const onBeforeLift = () => {};
  const [show, setShow] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userDevice, setUserDevice] = useState(devices.OTHERS);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const location = useLocation();

  function checkDevice() {
    const showPopupFlag = JSON.parse(
      sessionStorage.getItem('appDownloadPopupFlag'),
    );
    if (showPopupFlag?.flag === 1) return;

    const userAgent = window.navigator.userAgent;
    const isIos = /iPhone|iPad|iPod/i.test(userAgent);
    const isAndroid = /Android/i.test(userAgent);
    if (isIos) {
      setUserDevice(devices.IOS);
      setShowDownloadPopup(true);
    } else if (isAndroid) {
      setUserDevice(devices.ANDRIOD);
      setShowDownloadPopup(true);
    } else {
      setUserDevice(devices.OTHERS);
      setShowDownloadPopup(false);
    }
  }

  function closeDownloadPopup() {
    setShowDownloadPopup(false);
    sessionStorage.setItem('appDownloadPopupFlag', JSON.stringify({ flag: 1 }));
  }

  return (
    <>
      <CacheBuster />
      <NetworkConnectivity />
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={onBeforeLift}
        >
          {!show && (
            // <div className="loading" style={{ background: '#fff' }}>
            <div
              style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#F7FAF9',
              }}
            >
              <RootLoader />
            </div>
          )}
          {show && (
            <ErrorBoundary>
              <RootComponent userInfo={userInfo} expired={expired} />
            </ErrorBoundary>
          )}

          {/* {!window?.ReactNativeWebView?.postMessage &&
          location.pathname !== '/register' &&
          location.pathname !== '/login' &&
          showDownloadPopup &&
          userDevice ? (
            <AppDownloadPopup device={userDevice} closePopup={closeDownloadPopup} />
          ) : null} */}
        </PersistGate>
      </Provider>
    </>
  );
};
export default App;
