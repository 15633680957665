import React, { Suspense, lazy, useEffect, useState, useContext } from 'react';
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import MediaQuery from 'react-responsive';
import axios from 'axios';
import { ConfigContext } from 'context';

import styles from 'components/Layout/styles.module.css';
import { logScreenChange } from 'utilities/analytics';
import Left from 'components/Left';
import { FITNESS_EXIT_CODE, SERVER_TYPE } from 'config/env';
import { RootLoader } from 'components/loaders/root-loader';
import { analytics } from 'utilities/gtm';
import {
  LANDING_PAGE_PROGRAMS,
  redirectionConfigs,
} from 'config/commonConfigs';
import { ProgramListing } from 'screens/Public/ProgramListing/ProgramListing';

const ChatList = lazy(() => lazyRetry(() => import('components/Chat/List')));
const Header = lazy(() => lazyRetry(() => import('components/Header')));
// const Left = lazy(() => lazyRetry(() => import('components/Left')));
const NotificationsContent = lazy(() =>
  lazyRetry(() => import('components/Header/Notifications')),
);
const Setting = lazy(() =>
  lazyRetry(() => import('components/HeaderDropdown/Setting/index')),
);
const Account = lazy(() =>
  lazyRetry(() => import('components/HeaderDropdown/Account/index')),
);
const PublicHome = lazy(() => lazyRetry(() => import('screens/Public/Home')));
const PublicLive = lazy(() => lazyRetry(() => import('screens/Public/Live')));
const PublicExplore = lazy(() =>
  lazyRetry(() => import('screens/Public/Explore/index')),
);
const ProgramsYou = lazy(() =>
  lazyRetry(() => import('screens/Public/Explore/programs')),
);
const Recommended = lazy(() =>
  lazyRetry(() => import('screens/Public/Nutrition/recommend/added')),
);
const ProgramsGoals = lazy(() =>
  lazyRetry(() => import('screens/Public/Explore/goals')),
);
const Genre = lazy(() =>
  lazyRetry(() => import('screens/Public/Explore/genre')),
);
const AdvDetails = lazy(() =>
  lazyRetry(() => import('screens/Public/Explore/advdetail')),
);
const DiyDetails = lazy(() =>
  lazyRetry(() => import('screens/Public/Explore/diydetail')),
);
const WeightLoss = lazy(() =>
  lazyRetry(() => import('screens/Public/Explore/weightLoss')),
);
const PublicUserProfile = lazy(() =>
  lazyRetry(() => import('screens/Public/UserProfile')),
);
const PublicCommunity = lazy(() =>
  lazyRetry(() => import('screens/Public/Community')),
);
const PublicCommunity2 = lazy(() =>
  lazyRetry(() => import('screens/Public/Community2')),
);
const PublicCourseView = lazy(() =>
  lazyRetry(() => import('screens/Public/CourseView')),
);
const UserRegister = lazy(() =>
  lazyRetry(() => import('screens/User/Register')),
);
const UserLogin = lazy(() => lazyRetry(() => import('screens/User/Login')));
const PageNotFound = lazy(() => lazyRetry(() => import('screens/Public/404')));
const BestPlan = lazy(() =>
  lazyRetry(() => import('screens/User/Register/bestPlan')),
);
const BestPlanMobile = lazy(() =>
  lazyRetry(() => import('screens/User/Register/bestPlanMobile')),
);
const SsoSignin = lazy(() => lazyRetry(() => import('screens/User/RCBSSO')));
const SubcriptionPaymentMobile = lazy(() =>
  lazyRetry(() => import('components/Modal/subcriptionPaymentMobile')),
);
const TermsCondition = lazy(() =>
  lazyRetry(() => import('screens/User/Register/termsCondition')),
);
const TermsUse = lazy(() =>
  lazyRetry(() => import('screens/User/Register/terms')),
);
const Webinar = lazy(() =>
  lazyRetry(() => import('screens/User/Webinar/WebinarQa')),
);
const UserConsent = lazy(() =>
  lazyRetry(() => import('screens/User/Register/userconsent')),
);
const SpecialProgramTerms = lazy(() =>
  lazyRetry(() => import('screens/Public/SpecialProgram/SpecialProgramTerms')),
);
const EmergencyContact = lazy(() =>
  lazyRetry(() => import('screens/User/Register/emergencyContact')),
);
const MobilePair = lazy(() =>
  lazyRetry(() => import('components/Modal/MobilePair')),
);
const Dashboard = lazy(() =>
  lazyRetry(() => import('screens/Public/Dashboard')),
);
const RecommendedList = lazy(() =>
  lazyRetry(() => import('screens/Public/Dashboard/RecommendedList')),
);
const Upcoming = lazy(() =>
  lazyRetry(() => import('screens/Public/Home/upcomingSession')),
);
const SavedSession = lazy(() =>
  lazyRetry(() => import('screens/Public/Home/savedSession/index')),
);
const OngoingPrograms = lazy(() =>
  lazyRetry(() => import('screens/Public/Home/ongoingPrograms/index')),
);
const TrainerProfile = lazy(() =>
  lazyRetry(() => import('screens/Public/TrainerProfile')),
);
const WorkoutSummary = lazy(() =>
  lazyRetry(() => import('screens/Public/WorkoutSummary')),
);
const DIYSession = lazy(() =>
  lazyRetry(() => import('screens/Public/Diy/diy-session')),
);
const Transaction = lazy(() =>
  lazyRetry(() => import('utilities/payment/transaction')),
);
const Personalisation = lazy(() =>
  lazyRetry(() => import('screens/User/Personalisation/index')),
);
const NonHustlers = lazy(() =>
  lazyRetry(() => import('screens/User/NonHustleUser')),
);
//const FacebookPublicLogin = lazy(() => lazyRetry(() => import('screens/Public/WorkoutSummary/indexFbPublic/index')));

//const VerifyPayment = lazy(() => lazyRetry(() => import('screens/Public/VerifyPayment')));
//const HustlersLogin = lazy(() => lazyRetry(() => import('screens/User/NonHustleUser/hustlerLogin')));
//const CreateAccount = lazy(() => lazyRetry(() => import('screens/User/NonHustleUser/CreateAccount/index')));
const DIYdetail = lazy(() =>
  lazyRetry(() => import('screens/Public/Diy/diy-detail')),
);
const Comment = lazy(() =>
  lazyRetry(() => import('screens/Public/Community/Share')),
);
const HelpSupport = lazy(() =>
  lazyRetry(() => import('screens/Public/Help-support')),
);
const ReadArticle = lazy(() =>
  lazyRetry(() => import('screens/Public/Help-support/read-article')),
);
const CreatePost = lazy(() =>
  lazyRetry(() => import('screens/Public/Community/CreatePost')),
);
const SearchResult = lazy(() =>
  lazyRetry(() => import('screens/Public/search/SearchResult')),
);
const Chats = lazy(() =>
  lazyRetry(() => lazyRetry(() => import('screens/Public/Community/Chat'))),
);
const ChatIndividual = lazy(() =>
  lazyRetry(() => import('screens/Public/Community/ChatIndividual')),
);
const TranierSession = lazy(() =>
  lazyRetry(() => import('screens/Public/Home/TranierSession')),
);
const Nutrition = lazy(() =>
  lazyRetry(() => import('screens/Public/Nutrition/index')),
);
const RegisterDNA = lazy(() =>
  lazyRetry(() => import('screens/Public/Nutrition/DNA/RegisterStepDna')),
);
const Leaderboard = lazy(() =>
  lazyRetry(() => import('screens/Public/Home/Leaderboard')),
);
const Challenges = lazy(() =>
  lazyRetry(() => import('screens/Public/Home/Challenges')),
);
const MyReports = lazy(() =>
  lazyRetry(() => import('screens/Public/MyReports')),
);
const Referral = lazy(() => lazyRetry(() => import('screens/Public/Referral')));
const AddressMapping = lazy(() =>
  lazyRetry(() => import('screens/Public/Nutrition/DNA/AddressDNA')),
);
const Blog = lazy(() => lazyRetry(() => import('screens/Public/Blog')));
const SpecialProgramLandingPage = lazy(() =>
  lazyRetry(() =>
    import('screens/Public/SpecialProgram/SpecialProgramLandingPage'),
  ),
);
const SpecialProgramStaticPage = lazy(() =>
  lazyRetry(() =>
    import('screens/Public/SpecialProgramStatic/SpecialProgramStaticPage'),
  ),
);

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh

          return window.location.reload(); // refresh the page
        } else if (error.name === 'ChunkLoadError') {
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const PrivateRoute = () => {
  const Authtoken = localStorage.getItem('token');

  return Authtoken ? (
    <Outlet />
  ) : (
    <Navigate to='/login' />
    // <Outlet />
  );
};

const PublicRoute = () => {
  const [isLoaded, setLoaded] = useState(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const isRcbLogout = searchParams.get('fitnessExit');
    const rcbtoken = searchParams.get('token');
    if (isRcbLogout === FITNESS_EXIT_CODE) {
      localStorage.clear();
      sessionStorage.clear();
    } else if (rcbtoken !== localStorage.getItem('rcbtoken')) {
      localStorage.clear();
      sessionStorage.clear();
    }
    setLoaded(true);
  }, []);
  const regStatus = localStorage.getItem('regStatus');
  if (isLoaded) {
    const Authtoken = localStorage.getItem('token');
    // return Authtoken ? Registerchecking(): <Outlet />;
    return Authtoken ? (
      regStatus ? (
        <Navigate to='/register' />
      ) : (
        <Navigate to='/home' />
      )
    ) : (
      <Outlet />
    );
  }

  return null;
};

const CommonRoute = () => {
  return <Outlet />;
};

const LocationChange = ({ children }) => {
  const location = useLocation();
  const configContext = useContext(ConfigContext);
  useEffect(() => {
    if (localStorage.getItem('appState') === '1') {
      localStorage.setItem('locationChanging', true);
    }
    if (
      !localStorage.getItem('token') &&
      !location.pathname.includes('login') &&
      !location.pathname.includes('signUp') &&
      !location.pathname.includes('mobile-auth') &&
      !location.pathname.includes('mobssosignin')
    ) {
      localStorage.setItem('lastURL', location.pathname);
    }
    if (
      location.pathname.includes('account') ||
      location.pathname.includes('bestPlan') ||
      location.pathname.includes('bestplan') ||
      location.pathname.includes('bestPlanMobile') ||
      location.pathname.includes('subcriptionPaymentMobile') ||
      location.pathname.includes('termsCondition') ||
      location.pathname.includes('mobilePair') ||
      location.pathname.includes('setting') ||
      location.pathname.includes('account') ||
      location.pathname.includes('notifications') ||
      location.pathname.includes('chatIndividual') ||
      location.pathname.includes('register') ||
      location.pathname.includes('emergencyContact') ||
      location.pathname.includes('workoutsummary') ||
      location.pathname.includes('personalisation') ||
      location.pathname.includes('help') ||
      location.pathname.includes('read-article') ||
      location.pathname.includes('trainer/session') ||
      location.pathname.includes('challenges') ||
      location.pathname.includes('login') ||
      location.pathname.includes('signUp') ||
      location.pathname.includes('search') ||
      location.pathname.includes('my-reports') ||
      location.pathname.includes('referral') ||
      location.pathname.includes('terms-condition') ||
      location.pathname.includes('userconsent') ||
      location.pathname.includes('special-program-terms') ||
      location.pathname.includes('workouts') ||
      location.pathname.includes('/payment/status')
    ) {
      configContext.setShowLeftMenu(false);
    } else {
      configContext.setShowLeftMenu(true);
    }
    if (
      location.pathname.includes('nutrition/registerDNA') ||
      location.pathname.includes('/diy/episode') ||
      location.pathname.includes('/diy/series') ||
      location.pathname.includes('/hustlin')
    ) {
      configContext.setPlainLayout(true);
    } else {
      configContext.setPlainLayout(false);
    }
    logScreenChange();
  }, [location]);
  return children;
};

const Routing = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [showLeft, setShowLeft] = useState(
    localStorage.getItem('token') ? true : false,
  );
  const configContext = useContext(ConfigContext);
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      if (JSON.parse(JSON.stringify(error))?.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('_expiredTime');
        configContext.setUserLoggedIn(false);
        window.location.href = '/login';
      }
    },
  );

  const fetch = window.fetch;
  window.fetch = (...args) =>
    (async (args) => {
      var result = await fetch(...args);
      if (result.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('_expiredTime');
        configContext.setUserLoggedIn(false);
        window.location.href = '/login';
      }
      return result;
    })(args);

  useEffect(() => {
    if (SERVER_TYPE === 'PROD') {
      analytics(window, document, 'script', 'dataLayer', 'GTM-NDBK8Q6L');
    }
    localStorage.setItem('appState', '1');
    if (localStorage.getItem('token')) {
      // setShowHeader(true);
      setShowLeft(true);
    } else {
      // setShowHeader(false);
      setShowLeft(false);
    }
  }, []);

  return (
    <>
      <div
        className={
          showHeader
            ? styles.main
            : !configContext.plainLayout
            ? styles.fulWidth
            : styles.component
        }
      >
        {showHeader && !configContext.plainLayout && (
          <Suspense
            fallback={
              <div className='loading'>
                <CircularProgress />
              </div>
            }
          >
            <Header />
          </Suspense>
        )}
        <div
          className={
            localStorage.getItem('token') && configContext?.showLeftMenu
              ? styles.container
              : !configContext.plainLayout
              ? window.location.pathname ===
                  '/explore/special-program/weight-and-watch-challenge' ||
                window.location.pathname ===
                  '/explore/special-program/workout-with-rcb-players'
                ? styles.specialFullwidth
                : styles.fullWidth
              : styles.component
          }
        >
          {localStorage.getItem('token') &&
            configContext?.showLeftMenu &&
            !configContext.plainLayout && (
              <MediaQuery minWidth={768}>
                <Left />
              </MediaQuery>
            )}
          <LocationChange>
            <Suspense
              fallback={
                <div
                  className='loading'
                  style={{ background: '#fff', height: '80vh' }}
                >
                  <RootLoader />
                  {/* <CircularProgress /> */}
                </div>
              }
            >
              <Routes>
                <Route path='/' element={<PrivateRoute />}>
                  {/* With Side Menu and Header */}
                  <Route
                    path='/'
                    element={redirectToRegister(<PublicHome />)}
                  />
                  <Route
                    path='/welcome'
                    element={redirectToRegister(<PublicHome />)}
                  />
                  <Route
                    path='/live'
                    element={redirectToRegister(<PublicLive />)}
                  />
                  <Route
                    path='/home'
                    element={redirectToRegister(<PublicHome />)}
                  />
                  <Route
                    path='explore/featured'
                    element={redirectToRegister(<PublicExplore />)}
                  />
                  <Route
                    path='explore/diy'
                    element={redirectToRegister(<PublicExplore />)}
                  />
                  <Route
                    path='explore/advance-diy'
                    element={redirectToRegister(<PublicExplore />)}
                  />
                  {/* <Route path="explore/special-programs" element={redirectToRegister(<PublicExplore />)} /> */}
                  {/* <Route path="explore" element={<PublicExplore />} /> */}
                  <Route
                    path='nutrition'
                    element={redirectToRegister(<Nutrition />)}
                  />
                  <Route
                    path='nutrition/sessionended'
                    element={redirectToRegister(<Nutrition />)}
                  />
                  <Route
                    path='/nutrition/:payment_type'
                    element={redirectToRegister(<Nutrition />)}
                  />
                  <Route
                    path='/dashboard'
                    element={redirectToRegister(<Dashboard />)}
                  />
                  <Route
                    path='/dashboard/sessionended'
                    element={redirectToRegister(<Dashboard />)}
                  />
                  <Route
                    path='/dashboard/sessionexited'
                    element={redirectToRegister(<Dashboard />)}
                  />
                  <Route
                    path='/upcoming'
                    element={redirectToRegister(<Upcoming />)}
                  />
                  <Route
                    path='/recommendedlist'
                    element={redirectToRegister(<RecommendedList />)}
                  />
                  <Route
                    path='/community'
                    element={redirectToRegister(<PublicCommunity />)}
                  />
                  <Route
                    path='/community2'
                    element={redirectToRegister(<PublicCommunity2 />)}
                  />
                  <Route path='/blog' element={redirectToRegister(<Blog />)} />
                  {/* <Route path="/historyworkout" element={<HistoryWorkout />} /> */}
                  <Route
                    path='user/:id'
                    element={redirectToRegister(<PublicUserProfile />)}
                  />
                  <Route
                    path='/explore/:tab/programs'
                    element={redirectToRegister(<ProgramsYou />)}
                  />
                  <Route
                    path='/recommended'
                    element={redirectToRegister(<Recommended />)}
                  />
                  <Route
                    path='explore/diy/goals/:id'
                    element={redirectToRegister(<ProgramsGoals />)}
                  />
                  <Route
                    path='explore/diy/genre/:id'
                    element={redirectToRegister(<Genre />)}
                  />
                  <Route
                    path='/advdetail'
                    element={redirectToRegister(<AdvDetails />)}
                  />
                  <Route
                    path='explore/diy/program/:id'
                    element={redirectToRegister(<DiyDetails />)}
                  />
                  <Route
                    path='/weightLoss'
                    element={redirectToRegister(<WeightLoss />)}
                  />
                  <Route
                    path='/live/:id'
                    element={redirectToRegister(<PublicCourseView />)}
                  />
                  <Route
                    path='/trainer-profile/:id/:name'
                    element={redirectToRegister(<TrainerProfile />)}
                  />
                  <Route
                    path='/community/comment/:id'
                    element={redirectToRegister(<Comment />)}
                  />
                  <Route
                    path='/community/post/'
                    element={redirectToRegister(<CreatePost />)}
                  />
                  <Route
                    path='/community/editPost/'
                    element={redirectToRegister(<CreatePost />)}
                  />
                  <Route
                    path='/Chats'
                    element={redirectToRegister(<Chats />)}
                  />
                  <Route
                    path='/Search'
                    element={redirectToRegister(<SearchResult />)}
                  />
                  <Route
                    path='/chat'
                    element={redirectToRegister(<ChatList />)}
                  />
                  <Route
                    path='/savedSession'
                    element={redirectToRegister(<SavedSession />)}
                  />
                  <Route
                    path='/ongoingPrograms'
                    element={redirectToRegister(<OngoingPrograms />)}
                  />
                  <Route
                    path='leaderboard'
                    element={redirectToRegister(<Leaderboard />)}
                  />
                  <Route
                    path='explore/diy/:id'
                    element={redirectToRegister(<DIYdetail />)}
                  />
                  <Route
                    path='/addressMapping'
                    element={redirectToRegister(<AddressMapping />)}
                  />
                  {/* No need Left Menu */}
                  <Route
                    path='*'
                    element={redirectToRegister(<PageNotFound />)}
                  />
                  <Route
                    path='/bestPlan'
                    element={redirectToRegister(<BestPlan />)}
                  />
                  <Route
                    path='/bestPlanMobile'
                    element={redirectToRegister(<BestPlanMobile />)}
                  />
                  <Route
                    path='/subcriptionPaymentMobile'
                    element={redirectToRegister(<SubcriptionPaymentMobile />)}
                  />

                  <Route
                    path='/mobilePair'
                    element={redirectToRegister(<MobilePair />)}
                  />
                  <Route
                    path='/setting'
                    element={redirectToRegister(<Setting />)}
                  />
                  <Route
                    path='/my-reports'
                    element={redirectToRegister(<MyReports />)}
                  />
                  <Route
                    path='/referral'
                    element={redirectToRegister(<Referral />)}
                  />
                  <Route
                    path='/account'
                    element={redirectToRegister(<Account />)}
                  />
                  <Route
                    path='/notifications'
                    element={redirectToRegister(<NotificationsContent />)}
                  />
                  <Route
                    path='/chatIndividual'
                    element={redirectToRegister(<ChatIndividual />)}
                  />
                  <Route path='/register' element={<UserRegister />} />
                  <Route
                    path='/emergencyContact'
                    element={<EmergencyContact />}
                  />
                  <Route
                    path='/workoutsummary'
                    element={redirectToRegister(<WorkoutSummary />)}
                  />
                  <Route
                    path='/personalisation'
                    element={redirectToRegister(<Personalisation />)}
                  />
                  <Route
                    path='/help'
                    element={redirectToRegister(<HelpSupport />)}
                  />
                  <Route
                    path='/trainer/session/:termID'
                    element={redirectToRegister(<TranierSession />)}
                  />
                  <Route
                    path='/read-article'
                    element={redirectToRegister(<ReadArticle />)}
                  />

                  <Route
                    path='/challenges'
                    element={redirectToRegister(<Challenges />)}
                  />
                  <Route
                    path='nutrition/dna'
                    element={redirectToRegister(<Nutrition />)}
                  />
                  <Route
                    path='nutrition/insight'
                    element={redirectToRegister(<Nutrition />)}
                  />

                  {/* Plain Layout */}
                  <Route
                    path='/nutrition/registerDNA'
                    element={redirectToRegister(<RegisterDNA />)}
                  />
                  <Route
                    exact
                    path='explore/diy/:titlename/:id/:programNo/:name'
                    element={redirectToRegister(<DIYSession />)}
                  />
                  <Route
                    exact
                    path='explore/diy/:titlename/:id/:programNo'
                    element={redirectToRegister(<DIYSession />)}
                  />
                  <Route
                    exact
                    path='explore/diy/:titlename/:id/:programNo/:name/:subname'
                    element={redirectToRegister(<DIYSession />)}
                  />
                  <Route
                    exact
                    path='explore/diy/:name/:id'
                    element={redirectToRegister(<DIYSession />)}
                  />
                  <Route
                    exact
                    path='/payment/status/:id'
                    element={redirectToRegister(<Transaction />)}
                  />
                  {/* <Route exact path="/diy-session-new/:id" element={redirectToRegister(<DIYSessionNew />)} /> */}
                  <Route
                    path='/termsCondition'
                    element={redirectToRegister(<TermsCondition />)}
                  />

                  {redirectionConfigs
                    .filter((item) => item.environment === SERVER_TYPE)
                    .map((item) => (
                      <Route
                        key={item.id}
                        path={item.url}
                        element={
                          <Navigate to={item.redirection} replace={true} />
                        }
                      />
                    ))}
                </Route>
                <Route path='/' element={<PublicRoute />}>
                  <Route
                    path='/login/invite/:inviteSeqNo'
                    element={<NonHustlers />}
                  />
                  {/* <Route path="/corporate/payment/transaction/verify" element={<VerifyPayment />} /> */}
                  <Route exact path='/login' element={<UserLogin />} />
                  <Route path='/login/rcb' element={<SsoSignin />} />
                  <Route path='/login/:sso' element={<UserLogin />} />
                  <Route path='/signup' element={<UserLogin />} />
                  {/* <Route path="/mobile-auth" element={<Auth />} /> */}
                </Route>
                <Route path='/' element={<CommonRoute />}>
                  <Route path='/terms-condition' element={<TermsUse />} />
                  <Route path='/hustle/webinar' element={<Webinar />} />
                  <Route path='/userconsent' element={<UserConsent />} />
                  <Route
                    path='/special-program-terms'
                    element={<SpecialProgramTerms />}
                  />
                  <Route
                    path='/explore/special-program/weight-and-watch-challenge'
                    element={<SpecialProgramLandingPage />}
                  />

                  {/* landing page routes */}
                  {LANDING_PAGE_PROGRAMS.map((item, i) => (
                    <Route
                      key={i}
                      path={item.routePath}
                      element={
                        <SpecialProgramStaticPage
                          programSeqNo={item.seqNo[SERVER_TYPE]}
                        />
                      }
                    />
                  ))}

                  {/* hustlin section */}
                  {SERVER_TYPE !== 'PROD' && (
                    <>
                      {/* <Route path="/hustlin" element={<ProgramListing />} /> */}
                      <Route
                        path='/explore/special-program/:id'
                        element={<SpecialProgramStaticPage />}
                      />
                    </>
                  )}
                </Route>
              </Routes>
            </Suspense>
          </LocationChange>
        </div>
      </div>
    </>
  );
};

export default Routing;

function RegisterRedirect({ children }) {
  const [registered, setRegistered] = useState(false);
  const specialProgramUser = localStorage.getItem('specialProgramUser');

  useEffect(() => {
    if (
      ![null, undefined, ''].includes(localStorage.getItem('regStatus')) &&
      !specialProgramUser
    ) {
      setRegistered(true);
    } else {
      setRegistered(false);
    }
  }, []);
  return (
    <>
      {registered && <Navigate to='/register' />}
      {!registered && children}
    </>
  );
}

function redirectToRegister(comp) {
  return <RegisterRedirect>{comp}</RegisterRedirect>;
}

// function validateProgramId (comp) {
//   const pathSplit = location.pathname.split('/');
//   const seqNo = pathSplit[pathSplit.length - 1].split('-');

//   let id = parseInt(seqNo[seqNo.length - 1]);
//   if (id === 6) {
//     return <Navigate to='/explore/diy/program/rcb-demo-4-119' replace={true}/>
//   } else {
//     return comp;
//   }
// }
