export const refineChatUser = (user) => {
  const data = { u: 1 };
  Object.keys(user).forEach((k) => {
    if (k == 'id') {
    } else if (k == 'icon') {
    } else if (k == 'userID') {
      data.id = user.userID;
    } else if (k == 'profilePic') {
      data.icon = user.profilePic;
    } else {
      data[k] = user[k];
    }
  });
  return data;
};
