import { Component } from 'react';
import { MODEL_TRANSITION_DELAY, RAZORPAY_KEY, SERVER_URL } from 'config/env';
import HustleLogoForPayment from 'themes/images/hustle-outfit-rcb-logo-payment.png';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styles from './styles.module.css';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import http from 'utilities/http';
import { getSessionStorage, setSessionStorage } from 'utilities/commonMethods';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Support from 'components/Support';
import { cleverTapEvent } from 'utilities/analytics';
import { CleverTapEvents } from 'utilities/clevertapEvents';

const _orderType = {
  ADV_DIY_SINGLE_EPISODE: 6,
  SPECIAL_PROGRAM: 7,
};
class PaymentIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: props?.checkoutSeqNo
        ? 'paymentVerification'
        : 'paymentProcessing',
      price: props?.price,
      orderType: props?.orderType ? props?.orderType : null,
      subscriptionInfo: props?.subscriptionInfo,
      checkoutInfo: null,
      showModel: true,
      signatureInfo: null,
      checkoutSeqNo: props?.checkoutSeqNo,
      countSession: props?.sessionCount,
      showSuccessModel: false,
      successData: null,
      productID: props?.productID,
      dnaProd: props?.dnaProd,
      lamifitInfo: props?.lamifitInfo,
      amountInfo:
        props?.orderType === 5
          ? { Lamifit: props?.price * 100 }
          : { Outfit: props?.price * 100 },
      showFailureModel: false,
      commonText: props?.text,
      planselected: props?.planselectedname,
    };
    this.initiatePayment = this.initiatePayment.bind(this);
    this.displayRazorPay = this.displayRazorPay.bind(this);
    this.checkout = this.checkout.bind(this);
    this.customCircularProgress = this.customCircularProgress.bind(this);
    this.confirmOrder = this.confirmOrder.bind(this);
    this.failureOkClick = this.failureOkClick.bind(this);
    this.closePaymentModal = this.closePaymentModal.bind(this);
  }

  componentDidMount() {
    if (this.state.action === 'paymentProcessing') {
      return this.checkout();
    } else if (this.state.action === 'paymentVerification') {
      return this.confirmOrder();
    }
  }

  closePaymentModal() {
    this.props.closePayment?.();
    sessionStorage.removeItem('bundlePurchasePayID');
  }

  async checkout() {
    sessionStorage.removeItem('productId');

    if (this.state?.orderType == 5 || this.state?.orderType == 3) {
      if (this.state?.orderType == 3) {
        try {
          http
            .get(`system/productmaster?planName=Advanced DIY`)
            .then((response) => {
              if (response.data[0]?.productID) {
                sessionStorage.setItem(
                  'productID',
                  response.data[0]?.productID,
                );
                this.createOrder();
              } else {
                this.closePaymentModal();
              }
            });
        } catch (error) {
          this.closePaymentModal();
          console.log(error);
        }
      } else if (this.state.orderType == 5) {
        if (this.state.commonText == 'dna') {
          http
            .get(`system/productmaster?planName=DNA ${this.state?.dnaProd}`)
            .then((response) => {
              if (response.data[0]?.productID) {
                sessionStorage.setItem(
                  'productID',
                  response.data[0]?.productID,
                );
                this.createOrder();
              } else {
                this.closePaymentModal();
              }
            })
            .catch(() => {
              this.closePaymentModal();
            });
        } else {
          http
            .get(
              `system/productmaster?planSeqNo=${this.state?.subscriptionInfo?.planSeqNo}&noOfMonths=${this.state?.subscriptionInfo?.noOfMonths}&planName=${this.props?.payserviceName}`,
            )
            .then((response) => {
              if (response.data[0]?.productID) {
                sessionStorage.setItem(
                  'productID',
                  response.data[0]?.productID,
                );
                this.createOrder();
              } else {
                this.closePaymentModal();
              }
            })
            .catch(() => {
              this.closePaymentModal();
            });
        }
      }
    } else if (this.state.orderType === _orderType.ADV_DIY_SINGLE_EPISODE) {
      try {
        const result = (
          await http.get(
            `system/productmaster?planSeqNo=${this.state?.subscriptionInfo?.planSeqNo}&noOfMonths=${this.state?.subscriptionInfo?.noOfMonths}&planName=${this.props?.payserviceName}`,
          )
        ).data;
        if (result[0].productID) {
          sessionStorage.setItem('productID', result[0].productID);
          this.createOrder();
        } else {
          this.closePaymentModal();
        }
      } catch (error) {
        this.closePaymentModal();
      }
    } else if (this.state.orderType === _orderType.SPECIAL_PROGRAM) {
      try {
        const result = (
          await http.get(
            `system/productmaster?planSeqNo=${this.state?.subscriptionInfo?.programSeqNo}&type=3`,
          )
        ).data;
        if (result[0].productID) {
          sessionStorage.setItem('productID', result[0].productID);
          this.createOrder();
        } else {
          this.closePaymentModal();
        }
      } catch (error) {
        this.closePaymentModal();
      }
    } else {
      try {
        if (![null, undefined, ''].includes(this.state?.planselected)) {
          http
            .get(
              `system/productmaster?planSeqNo=${this.state?.subscriptionInfo?.planSeqNo}&noOfMonths=${this.state?.subscriptionInfo?.noOfMonths}&planName=${this.state?.planselected}`,
            )
            .then((response) => {
              if (response.data?.[0]?.productID) {
                // SET SESSION STORAGE
                sessionStorage.setItem('productID', response.data[0].productID);
                this.createOrder();
              } else {
                this.closePaymentModal();
              }
            })
            .catch(() => {
              this.closePaymentModal();
            });
        } else {
          http
            .get(
              `system/productmaster?planSeqNo=${this.state?.subscriptionInfo?.planSeqNo}&noOfMonths=${this.state?.subscriptionInfo?.noOfMonths}`,
            )
            .then((response) => {
              if (response.data?.[0]?.productID) {
                // SET SESSION STORAGE
                sessionStorage.setItem('productID', response.data[0].productID);
                this.createOrder();
              } else {
                this.closePaymentModal();
              }
            })
            .catch(() => {
              this.closePaymentModal();
            });
        }
      } catch (error) {
        this.closePaymentModal();
      }
    }
  }

  createOrder() {
    let apiData = {};
    if (this.props.isReoccuring) {
      apiData = {
        orderType: this.state?.orderType,
        amount: this.state?.price * 100,
        subscriptionInfo: this.state?.subscriptionInfo,
        lamifitInfo: this.state?.lamifitInfo,
        amountInfo: this.state?.amountInfo,
        productID: parseInt(sessionStorage.getItem('productID')),
        recurringPeriod: {
          planSeqNo: this.state?.subscriptionInfo?.planSeqNo,
          noOfMonths: this.state?.subscriptionInfo?.noOfMonths,
          productID: parseInt(sessionStorage.getItem('productID')),
        },
      };
    } else if (this.state.orderType === _orderType.ADV_DIY_SINGLE_EPISODE) {
      apiData = {
        orderType: 3,
        subscriptionInfo: {
          programSeqNo: this.props.programSeqNo,
          noOfMonths: 0,
          diySeqNo: this.state?.subscriptionInfo?.planSeqNo,
        },
        productID: parseInt(sessionStorage.getItem('productID')),
      };
    } else if (this.state.orderType === _orderType.SPECIAL_PROGRAM) {
      apiData = {
        orderType: 3,
        amount: this.state?.price,
        subscriptionInfo: this.state?.subscriptionInfo,
        productID: parseInt(sessionStorage.getItem('productID')),
      };
      if (this.props.bundleInfo) {
        apiData.lamifitInfo = this.props.bundleInfo;
      }
    } else {
      apiData = {
        orderType: this.state?.orderType,
        amount: this.state?.price * 100,
        subscriptionInfo: this.state?.subscriptionInfo,
        lamifitInfo: this.state?.lamifitInfo,
        amountInfo: this.state?.amountInfo,
        productID: parseInt(sessionStorage.getItem('productID')),
      };
    }

    if (this.props.addOnInfo) {
      apiData.addOnInfo = this.props.addOnInfo;
    }

    if (this.props?.couponCode && this.props?.couponTransactionType) {
      apiData.couponCode = this.props?.couponCode;
      apiData.transactionType = this.props?.couponTransactionType;
    }

    if (this.props?.coinsNeeded) {
      apiData.coinsNeeded = this.props?.coinsNeeded;
      apiData.redeemCoins = 1;
    }

    let productname;

    if (this.state.lamifitInfo) {
      productname = this.state?.lamifitInfo?.serviceName;
    } else {
      productname = this.state?.planselected;
    }

    http
      .post(`subscription/createrporder`, apiData)
      .then((response) => {
        if (response && response.data) {
          this.setState({ checkoutInfo: response.data, showModel: false });
          this.setState({
            planselected: productname,
          });
          let alldata = {
            value: this.state.price,
            productID: sessionStorage.getItem('productID'),
            nameofproduct: productname,
          };
          sessionStorage.setItem('purchasedata', JSON.stringify(alldata));

          this.initiatePayment();
          cleverTapEvent('Payment-Initiate', {
            Event_name: 'Payment_Initiate',
            Plan_type: productname,
            price: this.state.price,
            productID: sessionStorage.getItem('productID'),
            auto_renewal: this.props?.isReoccuring ? 'Yes' : 'No',
          });
          if (this.props.selectedAddons?.length) {
            CleverTapEvents.addonsOverallPaymentInitiated(
              this.props.selectedAddons,
            );
            setSessionStorage('selectedAddons', this.props.selectedAddons);
          }
          if (this.props?.cleverTapInfo) {
            cleverTapEvent('Overall_Payment_initiated', {
              ...this.props.cleverTapInfo?.addonsDetails,
              Event_name: 'Overall_Payment_initiated',
              Plan_type: this.props?.cleverTapInfo?.planType,
              Plan_name: this.props?.cleverTapInfo?.planName,
              Coupon_code: this.props.cleverTapInfo?.couponCode,
              price: this.props?.cleverTapInfo?.price,
              productID: sessionStorage.getItem('productID'),
              auto_renewal: this.props?.isReoccuring ? 'Yes' : 'No',
              Utm_Source: ![null, undefined, '', 'undefined'].includes(
                localStorage.getItem('utm_source'),
              )
                ? localStorage.getItem('utm_source')
                : 'Organic',
              Utm_Medium: ![null, undefined, '', 'undefined'].includes(
                localStorage.getItem('utm_medium'),
              )
                ? localStorage.getItem('utm_medium')
                : 'Organic',
              Utm_Campaign: ![null, undefined, '', 'undefined'].includes(
                localStorage.getItem('utm_campaign'),
              )
                ? localStorage.getItem('utm_campaign')
                : 'Organic',
            });
            cleverTapEvent(
              'Overall_Payment_initiated_' +
                this.props?.cleverTapInfo?.planName,
              {
                Event_name:
                  'Overall_Payment_initiated_' +
                  this.props?.cleverTapInfo?.planName,
                Plan_type: this.props?.cleverTapInfo?.planType,
                Plan_name: this.props?.cleverTapInfo?.planName,
                Coupon_code: this.props.cleverTapInfo?.couponCode,
                price: this.props?.cleverTapInfo?.price,
                productID: sessionStorage.getItem('productID'),
                auto_renewal: this.props?.isReoccuring ? 'Yes' : 'No',
                Utm_Source: ![null, undefined, '', 'undefined'].includes(
                  localStorage.getItem('utm_source'),
                )
                  ? localStorage.getItem('utm_source')
                  : 'Organic',
                Utm_Medium: ![null, undefined, '', 'undefined'].includes(
                  localStorage.getItem('utm_medium'),
                )
                  ? localStorage.getItem('utm_medium')
                  : 'Organic',
                Utm_Campaign: ![null, undefined, '', 'undefined'].includes(
                  localStorage.getItem('utm_campaign'),
                )
                  ? localStorage.getItem('utm_campaign')
                  : 'Organic',
              },
            );
            cleverTapEvent('Subscription_Add to cart', {
              Event_name: 'Subscription_Add to cart',
              Plan: this.props?.cleverTapInfo?.planName,
              duration_of_the_plan: 28,
              Utm_Source: ![null, undefined, '', 'undefined'].includes(
                localStorage.getItem('utm_source'),
              )
                ? localStorage.getItem('utm_source')
                : 'Organic',
              Utm_Medium: ![null, undefined, '', 'undefined'].includes(
                localStorage.getItem('utm_medium'),
              )
                ? localStorage.getItem('utm_medium')
                : 'Organic',
              Utm_Campaign: ![null, undefined, '', 'undefined'].includes(
                localStorage.getItem('utm_campaign'),
              )
                ? localStorage.getItem('utm_campaign')
                : 'Organic',
            });
            //for payment complete event
            sessionStorage.setItem(
              'cleverTapInfo',
              JSON.stringify(this.props?.cleverTapInfo),
            );
          }
        } else {
          this.closePaymentModal();
        }
      })
      .catch((error) => {
        this.setState({ checkoutInfo: null, showModel: false });
        if (this.props?.closeParentModal) {
          this.props?.closeParentModal();
        }

        if (error?.response?.data?.errorCode === 'PENDING_TRANSACTION') {
          let passdata = {
            paymentVerified: this.props?.samelocation,
          };
          this.props?.router.navigate(`/payment/status/pending`, {
            state: { data: passdata },
          });
        } else {
          Support.showError('Something went wrong!');
        }
        this.closePaymentModal();
      });
  }

  confirmOrder() {
    this.setState({ showModel: true });
    const apiData = {
      checkoutSeqNo: this.state.checkoutSeqNo,
      productID: parseInt(this.state.productID),
    };

    http
      .post(`subscription/confirmorder`, apiData)
      .then((response) => {
        if (response && response.data) {
          sessionStorage.setItem(
            'bundlePurchasePayID',
            response?.data?.paymentID,
          );

          if (response.data.status != 3) {
            const cleverTapInfo = JSON.parse(
              sessionStorage.getItem('cleverTapInfo'),
            );
            const selectedAddons = getSessionStorage('selectedAddons');
            sessionStorage.removeItem('selectedAddons');
            if (selectedAddons?.length) {
              CleverTapEvents.addonsOverallPaymentCompleted(selectedAddons);
            }
            if (cleverTapInfo) {
              cleverTapEvent('Overall_Payment_completed', {
                ...cleverTapInfo?.addonsDetails,
                Event_name: 'Overall_Payment_completed',
                Plan_type: cleverTapInfo?.planType,
                Plan_name: cleverTapInfo?.planName,
                Coupon_code: cleverTapInfo?.couponCode,
                price: cleverTapInfo?.price,
                productID: sessionStorage.getItem('productID'),
                auto_renewal: this.props?.isReoccuring ? 'Yes' : 'No',
                Utm_Source: ![null, undefined, '', 'undefined'].includes(
                  localStorage.getItem('utm_source'),
                )
                  ? localStorage.getItem('utm_source')
                  : 'Organic',
                Utm_Medium: ![null, undefined, '', 'undefined'].includes(
                  localStorage.getItem('utm_medium'),
                )
                  ? localStorage.getItem('utm_medium')
                  : 'Organic',
                Utm_Campaign: ![null, undefined, '', 'undefined'].includes(
                  localStorage.getItem('utm_campaign'),
                )
                  ? localStorage.getItem('utm_campaign')
                  : 'Organic',
              });
              cleverTapEvent(
                'Overall_Payment_completed_' + cleverTapInfo?.planName,
                {
                  Event_name:
                    'Overall_Payment_completed_' + cleverTapInfo?.planName,
                  Plan_type: cleverTapInfo?.planType,
                  Plan_name: cleverTapInfo?.planName,
                  Coupon_code: cleverTapInfo?.couponCode,
                  price: cleverTapInfo?.price,
                  productID: sessionStorage.getItem('productID'),
                  auto_renewal: this.props?.isReoccuring ? 'Yes' : 'No',
                  Utm_Source: ![null, undefined, '', 'undefined'].includes(
                    localStorage.getItem('utm_source'),
                  )
                    ? localStorage.getItem('utm_source')
                    : 'Organic',
                  Utm_Medium: ![null, undefined, '', 'undefined'].includes(
                    localStorage.getItem('utm_medium'),
                  )
                    ? localStorage.getItem('utm_medium')
                    : 'Organic',
                  Utm_Campaign: ![null, undefined, '', 'undefined'].includes(
                    localStorage.getItem('utm_campaign'),
                  )
                    ? localStorage.getItem('utm_campaign')
                    : 'Organic',
                },
              );
              cleverTapEvent('Subscription_Completed', {
                Event_name: 'Subscription_Completed',
                Plan_name: this.props?.cleverTapInfo?.planName,
                Plan_type: this.props?.cleverTapInfo?.planType,
                duration_of_the_plan: 28,
                Utm_Source: ![null, undefined, '', 'undefined'].includes(
                  localStorage.getItem('utm_source'),
                )
                  ? localStorage.getItem('utm_source')
                  : 'Organic',
                Utm_Medium: ![null, undefined, '', 'undefined'].includes(
                  localStorage.getItem('utm_medium'),
                )
                  ? localStorage.getItem('utm_medium')
                  : 'Organic',
                Utm_Campaign: ![null, undefined, '', 'undefined'].includes(
                  localStorage.getItem('utm_campaign'),
                )
                  ? localStorage.getItem('utm_campaign')
                  : 'Organic',
              });
            }
            if (this.state.orderType == 5) {
              this.setState({ successData: response.data });
              sessionStorage.setItem('paymentid', response?.data?.paymentID);

              if (this.state.commonText == 'dna') {
                let passdata = {
                  paymentVerified: this.props?.samelocation,
                  text: this.state.commonText,
                };

                this.props?.router.navigate(
                  `/payment/status/success?payment_id=${response?.data?.paymentID}`,
                  {
                    state: { data: passdata },
                  },
                );
              } else if (this.state.commonText == 'insight') {
                let passdata = {
                  paymentVerified: this.props?.samelocation,
                  text: this.state.commonText,

                  getMealData: this.props?.getMealData,
                };

                this.props?.router.navigate(
                  `/payment/status/success?payment_id=${response?.data?.paymentID}`,
                  {
                    state: { data: passdata },
                  },
                );
              }

              // this.props?.paymentVerified({ status: response.data });
            } else if (this.state.orderType == 4) {
              this.setState({ successData: 2 });
              let passdata = {
                paymentVerified: this.props?.samelocation,
                text: 'topup',
              };

              this.props?.router.navigate(
                `/payment/status/success?payment_id=${response?.data?.paymentID}`,
                {
                  state: { data: passdata },
                },
              );
              // this.props?.paymentVerified({ status: response.data, orderType: 4 });
            } else {
              this.setState({ successData: 2 });
              let passdata = {
                paymentVerified: this.props?.samelocation,
                text: this.state.commonText,
                subtext: 'all',
              };
              this.props?.router.navigate(
                `/payment/status/success?payment_id=${response?.data?.paymentID}`,
                {
                  state: { data: passdata },
                },
              );
            }
          } else if (response.data.status == 3) {
            sessionStorage.removeItem('bundlePurchasePayID');
            this.setState({ action: 'paymentFailure' });

            let passdata = {
              paymentVerified: this.props?.samelocation,
            };
            this.props?.router.navigate(`/payment/status/fail`, {
              state: { data: passdata },
            });
          } else {
            this.setState({ action: 'paymentSuccess' });
          }

          sessionStorage.removeItem('productID');
          sessionStorage.removeItem('cleverTapInfo');
        }
        this.setState({ orderType: null });
        sessionStorage.removeItem('productID');
        this.setState({ showModel: false });
      })
      .catch((error) => {
        sessionStorage.removeItem('bundlePurchasePayID');
        this.setState({ showModel: false });
        if (error?.response?.data && error?.response?.data?.message) {
          Support.showError(error.response.data.message, {
            toastId: 'success1',
          });
          this.props?.router.navigate(window.location.pathname);
        }
        this.closePaymentModal();
      });
  }

  initiatePayment() {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    document.body.appendChild(script);
    script.onload = this.displayRazorPay.bind(this);
  }

  displayRazorPay() {
    let options = {};
    let storageData, mobileNO;
    storageData = JSON.parse(localStorage.getItem('persist:root'));
    mobileNO = JSON.parse(storageData.session)?.user?.account?.mobileNO;
    if (this.props.isReoccuring) {
      options = {
        key: RAZORPAY_KEY,
        amount: this.state.checkoutInfo?.orderedData?.amount,
        currency: 'INR',
        name: 'Hustle By RCB',
        image: HustleLogoForPayment,
        subscription_id: this.state.checkoutInfo?.orderedData?.id,
        handler: function (response) {},
        method: { qr: false },
        prefill: { contact: mobileNO },
        modal: {
          ondismiss: function () {
            this.closePaymentModal();
            this.props?.popupClose();
            cleverTapEvent('Transaction-Cancelled', {
              Event_name: 'Transaction_Cancelled',
              price: this.state.price,
              currency: 'INR',
            });
            window.location.assign(window.location.pathname);
          }.bind(this),
        },
        callback_url: `${SERVER_URL}subscription/redirectorder/${
          this.state?.checkoutInfo?.checkouSeqNo
        }?url=${encodeURIComponent(window.location.href)}`,
        redirect: true,
      };
    } else {
      options = {
        key: RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: this.state.checkoutInfo?.orderedData?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: 'INR',
        name: 'Hustle By RCB',
        // description: 'Desc',
        image: HustleLogoForPayment,
        order_id: this.state.checkoutInfo?.orderedData?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {},
        method: { qr: false },
        prefill: {
          contact: mobileNO,
        },
        modal: {
          ondismiss: function () {
            this.closePaymentModal();
            this.props?.popupClose?.();
            window.location.assign(window.location.pathname);
            cleverTapEvent('Transaction-Cancelled', {
              Event_name: 'Transaction_Cancelled',
              price: this.state.checkoutInfo?.orderedData?.amount,
              currency: 'INR',
            });
          }.bind(this),
        },
        callback_url: `${SERVER_URL}subscription/redirectorder/${
          this.state?.checkoutInfo?.checkouSeqNo
        }?url=${encodeURIComponent(window.location.href)}`,
        redirect: true,
      };
    }

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    const elements = document.getElementsByClassName('razorpay-checkout-frame');
    if (elements && elements.length) {
      for (const el of elements) {
        el.style['pointer-events'] = 'all';
      }
    }
  }

  customCircularProgress(props) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant='determinate'
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant='indeterminate'
          disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === 'light' ? '#DF002C' : '#308fe8',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  failureOkClick() {
    this.setState({ showFailureModel: false });
    window.history.replaceState(null, null, window.location.pathname);
    sessionStorage.removeItem('planKey');
    sessionStorage.removeItem('planName');
    sessionStorage.removeItem('totAmt');
    sessionStorage.removeItem('perplanMonth');
    sessionStorage.removeItem('paymentid');
    sessionStorage.removeItem('perpaycode');
    sessionStorage.removeItem('perremainDays');
    sessionStorage.removeItem('noOfMonth');
    sessionStorage.removeItem('smarttotAmt');
    sessionStorage.removeItem('smartpaycode');
    sessionStorage.removeItem('smartremainDays');
    sessionStorage.removeItem('topup');
    sessionStorage.removeItem('productID');
    sessionStorage.removeItem('productname');
    sessionStorage.removeItem('productmrp');
    sessionStorage.removeItem('smartCatNameforFoodPre');
  }

  render() {
    return (
      <div>
        {(this.state?.action === 'paymentProcessing' ||
          this.state?.action === 'paymentVerification') && (
          <Modal
            open={this.state.showModel}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className={styles.modalBackground}
            style={{ backgroundColor: '#ffffff' }}
            snapPoints={({ maxHeight }) => [0.45 * maxHeight, 0.83 * maxHeight]}
            BackdropProps={{
              timeout: MODEL_TRANSITION_DELAY,
            }}
            closeAfterTransition
          >
            <Box className={styles.modal} in={this.state.showModel}>
              <div className={styles.contentArea}>
                <div>{this.customCircularProgress(null)}</div>
                {this.state?.action === 'paymentProcessing' && (
                  <span>Initiating the payment...</span>
                )}
                {this.state?.action === 'paymentVerification' && (
                  <span>Processing the payment...</span>
                )}
              </div>
            </Box>
          </Modal>
        )}
      </div>
    );
  }
}

// eslint-disable-next-line react/display-name
export default (props) => {
  const navigate = useNavigate();
  const router = { navigate };
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return <PaymentIntegration {...props} router={router} isMobile={isMobile} />;
};
