import styles from './style.module.css';
import { useEffect } from 'react';
import { useState } from 'react';
import http from 'utilities/http';
import { ButtonLoader } from 'components/loaders/ButtonLoader';
import { Button } from '@mui/material';
import Support from 'components/Support';

const statuses = {
  IDLE: 1,
  LOADING: 2,
  SUCCESS: 3,
};

const CouponInput = ({
  transactionType,
  setDiscountedPrice,
  productPrice,
  productDefaultDiscount = 0,
  planSeqNo,
  planName,
  planNoOfMonths,
  setCouponCode,
  isAdvancedDiy = false,
  isDna = false,
  isSpecialProgram = false,
  selectedDuration,
  selectedPlan,
  availableCoins,
  coinValue,
  setCoinValue,
}) => {
  if (!availableCoins) availableCoins = 0;
  if (!coinValue) coinValue = 0;
  if (!setCoinValue) setCoinValue = () => {};

  const [apiStatus, setApiStatus] = useState(statuses.IDLE);
  const [inputValue, setInputValue] = useState('');
  const [isInputValidated, setIsInputValidated] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (apiStatus === statuses.SUCCESS) return onRemoveClick();

    if (!inputValue || !validateInput()) return;

    try {
      if (apiStatus == statuses.LOADING) return;
      if (!productPrice || !setDiscountedPrice || !setCouponCode) {
        throw new Error('Something went wrong!');
      }
      setApiStatus(statuses.LOADING);

      const productId = await getProductId();
      const couponDetails = await getCouponDetails(productId);
      const finalPrice = findDiscountedPrice(
        productPrice,
        couponDetails.discountPercentage,
        couponDetails.maxDiscount,
        productDefaultDiscount
      );

      setDiscountedPrice(finalPrice);
      setApiStatus(statuses.SUCCESS);
      setCouponCode(inputValue);
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        Support.showError(err.response.data.message);
      } else if (err.message) {
        Support.showError(err.message);
      } else {
        Support.showError('Something went wrong!');
      }
      setCouponCode('');
      setApiStatus(statuses.IDLE);
    }
  }

  async function getProductId() {
    if (!planSeqNo || !planNoOfMonths || !planName) {
      throw new Error('Something went wrong!');
    }

    let response = [];
    if (isAdvancedDiy) {
      response = (await http.get('system/productmaster?planName=Advanced DIY')).data;
    } else if (isDna) {
      response = (await http.get(`system/productmaster?planName=DNA ${planName}`)).data;
    } else if (isSpecialProgram) {
      response = (await http.get(`system/productmaster?planSeqNo=${planSeqNo}&type=3`)).data;
    } else {
      response = (
        await http.get(`system/productmaster?planSeqNo=${planSeqNo}&noOfMonths=${planNoOfMonths}&planName=${planName}`)
      ).data;
    }

    if (!response[0].productID) throw new Error('Server error!, please try again later');

    return response[0].productID;
  }

  async function getCouponDetails(productId) {
    if (!inputValue || !transactionType || !productId) {
      throw new Error('Something went wrong!');
    }
    const response = (
      await http.get(
        `coupons/getvalidity?couponCode=${inputValue}&productID=${productId}&transactionType=${transactionType}`
      )
    ).data;

    if (!response.maxDiscount) throw new Error('Server error!, please try again later');
    return response;
  }

  function onInputChange(e) {
    setIsInputValidated(true);
    setInputValue(e.target.value);
  }

  function findDiscountedPrice(price, percentage, maxDiscount, defaultDiscount) {
    let couponDiscount = 0;
    if (percentage) {
      couponDiscount = (price * percentage) / 100;
    } else {
      couponDiscount = price - maxDiscount;
    }

    let discountedPrice = 0;

    if (couponDiscount > maxDiscount) {
      discountedPrice = Math.round(price - maxDiscount - defaultDiscount);
    } else {
      discountedPrice = Math.round(price - couponDiscount - defaultDiscount);
    }

    // if (coinValue) {
    //   discountedPrice = discountedPrice - coinValue;
    // }

    return discountedPrice;
  }

  function onRemoveClick() {
    setInputValue('');
    setApiStatus(statuses.IDLE);
    setCouponCode('');
    setDiscountedPrice(0);
  }

  function validateInput() {
    if (inputValue.length === 8) {
      setIsInputValidated(true);
      return true;
    } else {
      setIsInputValidated(false);
      return false;
    }
  }

  // function devHelper(array) {
  //   array.forEach((element, i) => {
  //     if (!element) console.log(element, i);
  //   });
  // }

  useEffect(() => {
    onRemoveClick();
  }, [selectedDuration, selectedPlan]);

  return (
    <>
      <p className={styles.inputTitle}>
        {apiStatus === statuses.SUCCESS ? 'Coupon Applied!' : 'Do you have any Coupons ?'}
      </p>
      <form className={styles.inpContainer} onSubmit={handleSubmit}>
        {inputValue && !isInputValidated ? (
          <p className={styles.errorMsg}>Coupons must consist of exactly 8 letters</p>
        ) : null}

        <input
          style={apiStatus === statuses.SUCCESS ? { color: 'var(--greenColor)' } : {}}
          type="text"
          value={inputValue}
          disabled={apiStatus === statuses.SUCCESS}
          onChange={onInputChange}
          spellCheck={false}
        />
        <Button sx={{ width: '75px' }} onClick={handleSubmit}>
          {apiStatus === statuses.LOADING && (
            <div className={styles.loadingWrap}>
              <ButtonLoader color="var(--primaryColor)" />
            </div>
          )}
          {apiStatus === statuses.SUCCESS && 'REMOVE'}
          {apiStatus === statuses.IDLE && 'APPLY'}
        </Button>
      </form>
      {/* <CoinsInput setCoinValue={setCoinValue} availableCoins={availableCoins || 0} /> */}
    </>
  );
};

export default CouponInput;
