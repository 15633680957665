export const NutritionIcon = ({ variant = '' }) => {
  return (
    <>
      {variant === '' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 21H13C17.4183 21 21 17.4183 21 13V12H3V13C3 17.4183 6.58172 21 11 21Z"
            stroke="#677572"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M10 15.75H10.75V14.25H10V15.75ZM6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75V14.25ZM10 14.25L6 14.25V15.75L10 15.75V14.25Z"
            fill="#677572"
          />
          <path
            d="M15 12C15 9.6 16.4 6 20 6C20 8.4 19.4 12 15 12Z"
            stroke="#677572"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M15.2799 8.25221C15.4854 8.61185 15.9435 8.7368 16.3032 8.53129C16.6628 8.32578 16.7877 7.86764 16.5822 7.508L15.2799 8.25221ZM5.54373 11.2695C5.34538 11.6331 5.47937 12.0887 5.84301 12.287C6.20665 12.4854 6.66222 12.3514 6.86057 11.9877L5.54373 11.2695ZM14.9026 7.59195L15.2799 8.25221L16.5822 7.508L16.2049 6.84774L14.9026 7.59195ZM6.86057 11.9877L9.22763 7.64813L7.91079 6.92986L5.54373 11.2695L6.86057 11.9877ZM16.2049 6.84774C14.3606 3.62013 9.69087 3.66636 7.91079 6.92986L9.22763 7.64813C10.4456 5.41522 13.6407 5.38358 14.9026 7.59195L16.2049 6.84774Z"
            fill="#677572"
          />
        </svg>
      )}
      {variant === 'hover' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 21H13C17.4183 21 21 17.4183 21 13V12H3V13C3 17.4183 6.58172 21 11 21Z"
            stroke="#141C1A"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path d="M10 15L6 15" stroke="#141C1A" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
          <path d="M15 12C15 9.6 16.4 6 20 6C20 8.4 19.4 12 15 12Z" stroke="#141C1A" strokeWidth="1.5" />
          <path
            d="M15.9315 7.88011L15.5543 7.21984C14.0011 4.50185 10.0687 4.54079 8.5697 7.28899L6.20264 11.6286"
            stroke="#141C1A"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </svg>
      )}
      {variant === 'active' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 21H13C17.4183 21 21 17.4183 21 13V12H3V13C3 17.4183 6.58172 21 11 21Z"
            stroke="#141C1A"
            strokeWidth="2"
            strokeLinecap="square"
          />
          <path d="M10 15L6 15" stroke="#DF002C" strokeWidth="2" strokeLinecap="square" />
          <path
            d="M15 12C15 9.6 16.4 6 20 6C20 8.4 19.4 12 15 12Z"
            stroke="#141C1A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.932 7.88011L15.5547 7.21984C14.0016 4.50185 10.0692 4.54079 8.57018 7.28899L6.20312 11.6286"
            stroke="#141C1A"
            strokeWidth="2"
            strokeLinecap="square"
          />
        </svg>
      )}
    </>
  );
};
