import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConfigContext } from 'context';

import Navhover from 'themes/images/nav-hover.png';
import VectorUser from 'themes/images/vectorUser.png';
import styles from './styles.module.css';
import TopUpModel from './TopUp/index';
import Thanksup from './TopUp/Thanksup';
import http from 'utilities/http';
import PaymentIntegration from 'utilities/payment';
import { HomeIcon } from 'components/Icons/Home';
import { LiveIcon } from 'components/Icons/Live';
import { ExploreIcon } from 'components/Icons/Explore';
import { NutritionIcon } from 'components/Icons/Nutrition';
import { CommunityIcon } from 'components/Icons/Community';
import { logEvent } from 'utilities/analytics';
import { MdOutlinePeopleAlt } from 'components/Icons/MdOutlinePeopleAlt';
import ReferralsModal from './Referrals/ReferralsModal';
import { ReferralsIcon } from 'components/Icons/ReferralsIcon';
import { GaEvents } from 'utilities/gaEvents';

const Left = () => {
  let navigate = useNavigate();
  let path = window.location.pathname;
  const [selectedMenu, SetSelectedMenu] = React.useState(path);
  const [isReferralsModal, setIsReferralsModal] = useState(false);
  const [topUp, setTopUp] = React.useState(false);
  const [thanksup, setThanksup] = React.useState(false);
  const [hover, setHover] = React.useState(null);
  const [showCommunity, setShowCommunity] = useState(0);
  const [organizationID, setOrganizationID] = useState();
  const [totalCoins, setTotalCoins] = useState(0);
  const configContext = useContext(ConfigContext);
  const [checkoutSeq, setCheckoutSeq] = useState(null);
  const [userSubscribed, setuserSubscribed] = useState(false);
  const [sessions, setSessions] = useState();
  const [showtopup, setShowtopup] = useState();

  useEffect(() => {
    let pathArray = path.split('/');
    let secondLevelLocation = pathArray[1];
    if (
      secondLevelLocation == 'diydetail' ||
      secondLevelLocation == 'diy-detail' ||
      secondLevelLocation == 'programs' ||
      secondLevelLocation == 'genre' ||
      secondLevelLocation == 'goals' ||
      secondLevelLocation == 'advdetail'
    ) {
      SetSelectedMenu('/explore/featured');
    } else if (
      selectedMenu == '/' ||
      secondLevelLocation == 'upcoming' ||
      secondLevelLocation == 'ongoingPrograms' ||
      secondLevelLocation == 'savedSession' ||
      secondLevelLocation == 'dashboard' ||
      secondLevelLocation == 'welcome'
    ) {
      SetSelectedMenu('/home');
    } else if (secondLevelLocation == '/live') {
      SetSelectedMenu('/live');
    } else if (selectedMenu == '/blog') {
      SetSelectedMenu('/community');
    } else if (selectedMenu == '/community') {
      SetSelectedMenu('/community');
    } else {
      SetSelectedMenu(window.location.pathname);
    }
  }, [path]);

  useEffect(() => {
    handleIncludeCommunity();
  }, []);

  useEffect(() => {
    //for payment flow
    if (sessionStorage.getItem('topup') == 1) {
      const orderId = new URLSearchParams(window.location.search)?.get('orderId');
      if (orderId) {
        setCheckoutSeq(orderId);
        // navigate('/home', { replace: true });
      }
    }
  }, []);
  function paymentVerified(response) {
    //once payment success this will work
    if (response && response.status) {
      window.history.replaceState(null, null, window.location.pathname);

      if (response.status.status === 2 && response.orderType === 4) {
        setTimeout(() => {
          setThanksup(true);
        }, [2000]);
      }
    }
  }
  const handleIncludeCommunity = async () => {
    const r = (await http.get('authentication/usersessiondetails')).data;

    if (![null, undefined, ''].includes(r)) {
      setShowCommunity(r.includeCommunity);
      setOrganizationID(r.organizationID);
      // setsubscriptionType(r.subscriptionType);
      setTotalCoins(r.userStats.totalCoins);
      configContext.setUserStats(r.userStats.activeInvite);
    }
  };
  useEffect(() => {
    http
      .get('subscription/user_subscription/active', null)
      .then((response) => {
        if (response.data.userSubscriptionDetail) {
          setShowtopup(response.data.userSubscriptionDetail.planActivities);
          setSessions(response.data.userSubscriptionDetail);
          if (response.data.userSubscriptionDetail.pauseEndDate) {
            const newDate = Date.now();

            const diffDate = newDate - response.data.userSubscriptionDetail.pauseEndDate;
            let daysDifference = Math.floor(diffDate / 1000 / 60 / 60 / 24);
            if (daysDifference > 0) {
              setuserSubscribed(true);
            } else {
              setuserSubscribed(false);
            }
          } else {
            setuserSubscribed(true);
          }
        }
      })
      .catch((error) => {});
  }, []);

  const handleMenu = (data, gaCta) => {
    SetSelectedMenu(data);
    GaEvents.sideNavigationClick(gaCta);
  };
  const ThanksCall = () => {
    setTopUp(false);
    setThanksup(true);
  };

  return (
    <>
      <div
        style={userSubscribed && !window?.ReactNativeWebView?.postMessage ? { paddingBottom: '80px' } : {}}
        className={styles.nav}
      >
        {!selectedMenu.includes('home') &&
          (!localStorage.getItem('Lead') ? (
            <Link
              to="/home"
              style={{ background: hover === 'home' && `url(${Navhover})` }}
              onClick={() => handleMenu('/home', 'home')}
              onMouseOver={() => setHover('home')}
              onMouseLeave={() => setHover(null)}
            >
              <HomeIcon variant={hover === 'home' ? 'hover' : ''} />
              <span>Home</span>
            </Link>
          ) : (
            <Link
              to="/welcome"
              style={{ background: hover === 'home' && `url(${Navhover})` }}
              onClick={() => handleMenu('/home', 'home')}
              onMouseOver={() => setHover('home')}
              onMouseLeave={() => setHover(null)}
            >
              <HomeIcon variant={hover === 'home' ? 'hover' : ''} />
              <span>Home</span>
            </Link>
          ))}
        {selectedMenu.includes('home') &&
          (!localStorage.getItem('Lead') ? (
            <Link to="/home" className={styles.selected} onClick={() => handleMenu('/home', 'home')}>
              {/* <img src={Assets.Images.HomeActive} alt="home-link" /> */}
              <HomeIcon variant="active" />
              <span>Home</span>
            </Link>
          ) : (
            <Link to="/welcome" className={styles.selected} onClick={() => handleMenu('/home', 'home')}>
              {/* <img src={Assets.Images.HomeActive} alt="home-link" /> */}
              <HomeIcon variant="active" />
              <span>Home</span>
            </Link>
          ))}
        {!selectedMenu.includes('live') && (
          <Link
            to="/live"
            style={{ background: hover === 'live' && `url(${Navhover})` }}
            onClick={() => handleMenu('/live', 'live')}
            onMouseOver={() => setHover('live')}
            onMouseLeave={() => setHover(null)}
          >
            <LiveIcon variant={hover === 'live' ? 'hover' : ''} />
            <span>Live</span>
          </Link>
        )}
        {selectedMenu.includes('live') && (
          <Link to="/live" className={styles.selected} onClick={() => handleMenu('/live', 'live')}>
            <LiveIcon variant="active" />
            <span>Live</span>
          </Link>
        )}
        {!selectedMenu.includes('explore') && (
          <Link
            to="/explore/featured"
            style={{ background: hover === 'explore' && `url(${Navhover})` }}
            onClick={() => handleMenu('/explore/featured', 'explore_diys')}
            onMouseOver={() => setHover('explore')}
            onMouseLeave={() => setHover(null)}
          >
            <ExploreIcon variant={hover === 'explore' ? 'hover' : ''} />
            <span>Explore DIYs</span>
          </Link>
        )}
        {selectedMenu.includes('explore') && (
          <Link
            to="/explore/featured"
            className={styles.selected}
            onClick={() => handleMenu('/explore', 'explore_diys')}
          >
            <ExploreIcon variant="active" />
            <span>Explore DIYs</span>
          </Link>
        )}
        {!selectedMenu.includes('nutrition') && (
          <Link
            to="/nutrition"
            style={{ background: hover === 'nutrition' && `url(${Navhover})` }}
            onClick={() => {
              handleMenu('/nutrition', 'nutrition');
              sessionStorage.clear();
            }}
            onMouseOver={() => setHover('nutrition')}
            onMouseLeave={() => setHover(null)}
          >
            <NutritionIcon variant={hover === 'nutrition' ? 'hover' : ''} />
            <span>Nutrition</span>
          </Link>
        )}
        {selectedMenu.includes('nutrition') && (
          <Link to="/nutrition" className={styles.selected} onClick={() => handleMenu('/nutrition', 'nutrition')}>
            <NutritionIcon variant="active" />
            <span>Nutrition</span>
          </Link>
        )}
        {!selectedMenu.includes('community') && [null, 1].includes(showCommunity) && (
          <Link
            to="/community"
            style={{ background: hover === 'community' && `url(${Navhover})` }}
            onClick={() => handleMenu('/community', 'community')}
            onMouseOver={() => setHover('community')}
            onMouseLeave={() => setHover(null)}
          >
            <CommunityIcon variant={hover === 'community' ? 'hover' : ''} />
            <span>Community</span>
          </Link>
        )}
        {selectedMenu.includes('community') && [null, 1].includes(showCommunity) && (
          <Link to="/community" className={styles.selected} onClick={() => handleMenu('/community', 'community')}>
            <CommunityIcon variant="active" />
            <span>Community</span>
          </Link>
        )}

        <div
          className={styles.referrals}
          style={{ background: hover === 'referrals' && `url(${Navhover})` }}
          onClick={() => {
            setIsReferralsModal(true);
            GaEvents.sideNavigationClick('referrals');
          }}
          onMouseOver={() => setHover('referrals')}
          onMouseLeave={() => setHover(null)}
        >
          <ReferralsIcon size="24px" color="#677572" hover={hover === 'referrals' && true} hoverColor="#141C1A" />
          <span>Referrals</span>
        </div>
        {userSubscribed &&
          !window?.ReactNativeWebView?.postMessage &&
          ![null, undefined, ''].includes(showtopup) &&
          showtopup[1]?.activityID == 'LIVE' && (
            <div
              className={styles.topUpBLock}
              onClick={() => {
                setTopUp(true);
                logEvent({
                  category: 'DASHBOARD',
                  action: 'TOP-UP_CLICK',
                  label: 'TOP-UP',
                });
              }}
            >
              <p className={styles.topUpBText}>Top-up </p>
              <img className={styles.topUpImage} src={VectorUser} alt="" />
            </div>
          )}
      </div>

      {topUp && (
        <TopUpModel
          handleClose={() => setTopUp(false)}
          totalCoins={totalCoins}
          organizationID={organizationID}
          open={topUp}
          sessions={sessions}
          callThanks={() => ThanksCall()}
        />
      )}
      {thanksup && (
        <Thanksup
          open={thanksup}
          handleClose={() => {
            setThanksup(false);
          }}
        />
      )}
      {checkoutSeq && (
        <PaymentIntegration
          closePayment={() => setCheckoutSeq(null)}
          orderType={4}
          checkoutSeqNo={checkoutSeq}
          productID={sessionStorage.getItem('productID')}
          samelocation={window.location.pathname}
          paymentVerified={(response) => paymentVerified(response)}
        />
      )}

      {isReferralsModal && <ReferralsModal isOpen={isReferralsModal} closeModal={() => setIsReferralsModal(false)} />}
    </>
  );
};

export default Left;
