import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  token: '',
  isLoggedIn: false,
  user: {},
};

const callbacks = {};

callbacks.updateToken = (state, action) => {
  state.isLoggedIn = true;
  state.token = action.payload.token;
};

callbacks.login = (state, action) => {
  state.isLoggedIn = true;
  state.token = action.payload.token;
  state.user = action.payload.user;
};

callbacks.logout = () => {
  return _.cloneDeep(initialState);
};

callbacks.updateUser = (state, action) => {
  state.user = action.payload.user;
};

const slice = createSlice({
  name: 'session',
  initialState: _.cloneDeep(initialState),
  reducers: callbacks,
});

const { actions, reducer } = slice;

export const { updateToken, login, logout, updateUser } = actions;

export const selectIsLoggedIn = (state) => state.session.isLoggedIn;
export const selectReferralCode = (state) => state.session.user?.referralCode || '';

export default reducer;
