export const LiveIcon = ({ variant = '' }) => {
  return (
    <>
      {variant === '' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.33984 19.5251H15.8976V4.61548H4.33984V19.5251Z" stroke="#677572" strokeWidth="1.5" />
          <path
            d="M20.3084 7.95483L15.8984 9.94289V14.2028L20.3084 16.1908V7.95483Z"
            stroke="#677572"
            strokeWidth="1.5"
          />
        </svg>
      )}
      {variant === 'hover' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.33984 19.5249H15.8976V4.61523H4.33984V19.5249Z" stroke="#141C1A" strokeWidth="1.5" />
          <path
            d="M13.3496 8L9.34961 8"
            stroke="#141C1A"
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M20.3089 7.95508L15.8989 9.94313V14.203L20.3089 16.1911V7.95508Z"
            stroke="#141C1A"
            strokeWidth="1.5"
          />
        </svg>
      )}
      {variant === 'active' && (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 25 24"
          //   style={{"enable-background":"new 0 0 25 24"}}
        >
          <g>
            <path className="st0" d="M17,20.3H3.9V3.9H17V20.3z M5.4,18.8h10.1V5.4H5.4V18.8z" />
          </g>
          <g>
            <path className="st0" d="M21.4,17.4l-5.9-2.7V9.5l5.9-2.7V17.4z M17,13.7l2.9,1.3V9.1L17,10.4V13.7z" />
          </g>
          <g>
            <rect x="8.9" y="7.4" className="st1" width="5.5" height="1.5" fill="#DF002C" />
          </g>
        </svg>
      )}
    </>
  );
};
