import { LAST_LOGIN_METHOD } from 'config/commonConfigs';
import { gaDataLayer } from './analytics';
import { getLocalStorage } from './commonMethods';
import moment from 'moment';

export class GaEvents {
  static getLastLoginMethod() {
    const lastLoginCode = getLocalStorage('lastLoginMethod');
    let lastLogin;
    switch (lastLoginCode) {
      case LAST_LOGIN_METHOD.APPLE:
        lastLogin = 'apple';
        break;
      case LAST_LOGIN_METHOD.EMAIL:
        lastLogin = 'email';
        break;
      case LAST_LOGIN_METHOD.FACEBOOK:
        lastLogin = 'facebook';
        break;
      case LAST_LOGIN_METHOD.GOOGLE:
        lastLogin = 'google';
        break;
      case LAST_LOGIN_METHOD.MOBILE:
        lastLogin = 'mobile';
        break;
      case LAST_LOGIN_METHOD.RCB:
        lastLogin = 'rcb';
        break;
      default:
        lastLogin = 'na';
        break;
    }
    return lastLogin;
  }

  static getFormattedText(text) {
    if (!text || typeof text !== 'string') return 'na';
    //regex to select whitespace and '-'
    const regex = /[ \-]/g;
    return text.trim().replace(regex, '_').toLowerCase();
  }

  static getCommonValues(userDetails) {
    const storageData = JSON.parse(localStorage.getItem('persist:root'));
    let userData = JSON.parse(storageData?.session)?.user;
    if (userDetails) userData = userDetails;

    let commonValues = {
      platform: window?.innerWidth < 767 ? 'mweb' : 'website',
      Page_url: window?.location?.href,
      User_ID: 'na',
      user_level: 'na',
      coins_count: 'na',
      logged_in_medium: 'na',
      user_status: 'guest',
    };

    if (userData?.userID) {
      commonValues.User_ID = userData?.userID;
      commonValues.user_level = userData?.userStats?.level?.level;
      commonValues.coins_count = userData?.userStats?.totalCoins;
      commonValues.logged_in_medium = this.getLastLoginMethod();
      commonValues.user_status = 'logged_in';
    }

    return commonValues;
  }

  static formSubmit(ctaText, formName, userDetails) {
    gaDataLayer({
      ...this.getCommonValues(userDetails),
      event: 'Form_submit',
      cta_text: this.getFormattedText(ctaText),
      form_name: formName,
    });
  }

  static videoInteraction(
    titleName,
    videoStatus,
    videoCurrentTimeInSeconds,
    videoProvider,
    category,
    headerTitle,
    contentId,
    videoLengthInSeconds,
  ) {
    let percentage = Math.round(
      (videoCurrentTimeInSeconds / videoLengthInSeconds) * 100,
    );
    if (!percentage && percentage !== 0) percentage = 'na';

    const lengthInMilli = videoLengthInSeconds * 1000;
    if (!lengthInMilli && lengthInMilli !== 0) lengthInMilli = 'na';

    const currentTimeInMilli = videoCurrentTimeInSeconds * 1000;
    if (!currentTimeInMilli && currentTimeInMilli !== 0)
      currentTimeInMilli = 'na';

    gaDataLayer({
      ...this.getCommonValues(),
      event: 'video_interaction',
      title_name: this.getFormattedText(titleName),
      video_status: videoStatus,
      video_current_time: Math.round(currentTimeInMilli),
      video_percent: percentage,
      video_provider: videoProvider,
      category,
      header_title: this.getFormattedText(headerTitle),
      content_id: contentId,
      video_length: Math.round(lengthInMilli),
    });
  }

  static workoutWithPlayers(eventName, diyTitle) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: eventName,
      diy_title: this.getFormattedText(diyTitle),
      program_title: 'workout_with_rcb_players',
    });
  }

  static hustleBannerClick(bannerDetails) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'hustle_banner_click',
      title_name: this.getFormattedText(bannerDetails?.name),
    });
  }

  static hustleHeaderClick(cta) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'hustle_header_click',
      cta_text: this.getFormattedText(cta),
    });
  }

  static viewProfile() {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'view_profile',
      cta_text: 'view_profile',
    });
  }

  static profileDropdownClick(data) {
    const ctaText = this.getFormattedText(data);
    //disabling event for logout cta
    if (ctaText === 'log_out') return;

    gaDataLayer({
      ...this.getCommonValues(),
      event: 'profile_drop_down_click',
      cta_text: ctaText,
    });
  }

  static updateInteration(cta, elementTitle) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'update_interaction',
      cta_text: this.getFormattedText(cta),
      element_title: elementTitle,
    });
  }

  static accountInteration(data) {
    const ctaText = this.getFormattedText(data?.Tittle);

    gaDataLayer({
      ...this.getCommonValues(),
      event: 'account_interaction',
      cta_text: ctaText,
    });
  }

  static settingsInteration(cta, elementTitle) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'settings_interaction',
      cta_text: this.getFormattedText(cta),
      element_title: elementTitle,
    });
  }

  static formFill(fieldName, formName) {
    const commonValues = this.getCommonValues();
    gaDataLayer({
      event: 'form_fill',
      field_name: this.getFormattedText(fieldName),
      form_name: formName,
      ...commonValues,
    });
  }

  static hustleSkipClick() {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'hustle_skip_click',
      cta_text: 'skip',
    });
  }

  static navigationMenuClick(cta) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'navigation_menu_click',
      cta_text: this.getFormattedText(cta),
    });
  }

  static signupSuccess() {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'sign_up_success',
    });
  }

  static loginSuccess(userDetails) {
    gaDataLayer({
      ...this.getCommonValues(userDetails),
      event: 'login_success',
    });
  }

  static logout() {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'logout',
      logged_in_medium: 'na',
      user_status: 'guest',
      User_ID: 'na',
      user_level: 'na',
      coins_count: 'na',
    });
  }

  static enrollInitiate(
    titleName,
    category,
    sessionID,
    timeStamp,
    difficultyLevel,
    duration,
    slotsLeft,
  ) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'enroll_initiate',
      title_name: this.getFormattedText(titleName),
      category: this.getFormattedText(category),
      content_id: sessionID,
      element_title: 'live',
      date_and_time: moment(timeStamp).format('DD MMM, YYYY|hh:mm a'),
      difficulty_level: this.getFormattedText(difficultyLevel),
      duration: duration + ' min',
      slots_left: slotsLeft,
    });
  }

  static bookClass(
    titleName,
    category,
    sessionID,
    timeStamp,
    difficultyLevel,
    duration,
    slotsLeft,
  ) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'book_class',
      title_name: this.getFormattedText(titleName),
      category: this.getFormattedText(category),
      content_id: sessionID,
      element_title: 'live',
      date_and_time: moment(timeStamp).format('DD MMM, YYYY|hh:mm a'),
      difficulty_level: this.getFormattedText(difficultyLevel),
      duration: duration + ' min',
      slots_left: slotsLeft,
    });
  }

  static enrollSuccess(
    titleName,
    category,
    sessionID,
    timeStamp,
    difficultyLevel,
    duration,
    slotsLeft,
  ) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'enroll_success',
      title_name: this.getFormattedText(titleName),
      category: this.getFormattedText(category),
      content_id: sessionID,
      element_title: 'live',
      date_and_time: moment(timeStamp).format('DD MMM, YYYY|hh:mm a'),
      difficulty_level: this.getFormattedText(difficultyLevel),
      duration: duration + ' min',
      slots_left: slotsLeft,
    });
  }

  static sideNavigationClick(cta) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'side_navigation_click',
      cta_text: this.getFormattedText(cta),
    });
  }

  static subNavigationClick(cta) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'sub_navigation_click',
      cta_text: this.getFormattedText(cta),
    });
  }

  static searchSuccess(searchTerm) {
    gaDataLayer({
      ...this.getCommonValues(),
      event: 'search_success',
      search_term: searchTerm,
    });
  }

  static videoClick(
    titleName,
    category,
    headerTitle,
    contentId,
    videoLengthInMin,
    elementTitle,
    ctaText,
    publishedDate,
  ) {
    const lengthInMilli = videoLengthInMin
      ? Number(videoLengthInMin) * 60 * 1000
      : 'na';
    const formattedDate = publishedDate
      ? moment(publishedDate).format('DD MMM YYYY')
      : 'na';

    if (!contentId) contentId = 'na';

    gaDataLayer({
      ...this.getCommonValues(),
      event: 'video_click',
      cta_text: this.getFormattedText(ctaText),
      title_name: this.getFormattedText(titleName),
      header_title: this.getFormattedText(headerTitle),
      content_id: contentId,
      category: this.getFormattedText(category),
      video_length: lengthInMilli,
      element_title: this.getFormattedText(elementTitle),
      published_date: formattedDate,
    });
  }

  static virtualPageView() {
    gaDataLayer({
      event: 'virtual_page_view',
    });
  }
}
