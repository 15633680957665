import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ConfigContext } from 'context';

import styles from './styles.module.css';
import CheatIcon from 'themes/images/CheatIcon.svg';
import { MODEL_TRANSITION_DELAY } from 'config/env';
import http from 'utilities/http';
import { JSONMerge } from 'utilities/commonMethods';

const Thanksup = (props) => {
  const configContext = useContext(ConfigContext);
  const { handleClose, open } = props;

  const handleNext = () => {
    http.get('authentication/usersessiondetails').then((response) => {
      if (![null, undefined, ''].includes(response.data)) {
        const patchJson = {
          coins: response.data.userStats?.totalCoins,
          level: response.data.userStats?.level?.level,
        };
        configContext.setLoginUserData(JSONMerge(configContext.loginUserData, patchJson));
      }
    });

    handleClose();
    sessionStorage.removeItem('topup');
    // navigate('/home', { replace: true });
  };

  return (
    <div>
      <Modal
        open={open}
        className={styles.modalBackground}
        BackdropProps={{
          timeout: MODEL_TRANSITION_DELAY,
        }}
        closeAfterTransition
      >
        <Box className={styles.verificationMailModal2} in={open}>
          <div className={styles.trainer}>
            <div className={styles.cheatChooseContainer}>
              <div className={styles.cheatTickList}>
                <div className={styles.trainerMailModalContent2}>
                  <img src={CheatIcon} alt="" className={styles.cheatTickIcon} />
                </div>
              </div>
              <p className={styles.ThreeDaysCheat}>Top up was successful.</p>
              <div className={styles.EnrollSessionHeader}>
                {/* <Link to={'/nutrition/online'}> */}
                <Button
                  variant="contained"
                  className={styles.nextButtonAgree}
                  style={{ marginTop: '2rem !important' }}
                  onClick={handleNext}
                >
                  <span className={styles.nextButtonText}>CONTINUE</span>
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Thanksup;
