export const redirectionConfigs = [
  {
    id: 1,
    environment: 'PREUAT',
    url: '/explore/diy/program/fitness-masterclass-with-rcb-players-21',
    seqNo: 21,
    redirection: '/explore/diy/program/rcb-demo-asynchornous-120',
    redirectionSeqNo: 120,
  },
  {
    id: 2,
    environment: 'PROD',
    url: '/explore/diy/program/workout-with-rcb-players-14',
    seqNo: 14,
    redirection: '/explore/diy/program/workout-with-rcb-players-29',
    redirectionSeqNo: 29,
  },
];

export const staticPageProgramSeqNo = {
  PROD: 29,
  PREUAT: 178,
  STAGE: 20,
  DEV: 20,
};

export const LOGGED_IN_METHOD = {
  1: 'email',
  2: 'mobile',
  3: 'google',
  4: 'facebook',
  5: 'apple',
  6: 'rcb',
};

export const DIY45_PRODUCT_ID = {
  PREUAT: 314,
  PROD: 75,
  DEV: 0,
  STAGE: 0,
};

export const COUPON_CODES = {
  28: 'HUSTLE02',
  34: 'GET6PACK',
  36: 'BUILDNOW',
  242: 'BUILDNOW',
};

export const LANDING_PAGE_PROGRAMS = [
  {
    name: 'six-pack-project',
    routePath: '/explore/special-program/6pack-project',
    couponCode: 'GET6PACK',
    seqNo: {
      PREUAT: 197,
      STAGE: 0,
      PROD: 34,
    },
    challengeID: {
      PREUAT: 197,
      STAGE: 19,
      PROD: 14,
    },
  },
  {
    name: 'workout-with-rcb-players',
    routePath: '/explore/special-program/workout-with-rcb-players',
    couponCode: null,
    seqNo: {
      PREUAT: 194,
      STAGE: 0,
      PROD: 29,
    },
    challengeID: {
      PREUAT: 195,
      STAGE: 16,
      PROD: 9,
    },
  },
];

export const WEIGHT_AND_WATCH_SEQNO = {
  PREUAT: 180,
  STAGE: 0,
  PROD: 28,
};

export const WEIGHT_AND_WATCH_CHALLENGE_ID = {
  PREUAT: 184,
  STAGE: 13,
  PROD: 8,
};

export const LAST_LOGIN_METHOD = {
  EMAIL: 1,
  GOOGLE: 2,
  APPLE: 3,
  MOBILE: 4,
  RCB: 5,
  FACEBOOK: 6,
};

export const PROGRAM_CONFIGS = {
  BUILD: {
    challengeID: {
      PREUAT: 0,
      STAGE: 0,
      PROD: 16,
    },
    seqNo: {
      PREUAT: 242,
      PROD: 36,
      STAGE: 33,
    },
    bestPlanCardPoints: [
      'Transformative Workouts: Tailored routines targeting every muscle group.',
      'Supportive Community: Join like-minded individuals on this empowering journey.',
      'Track Your Progress: Easy-to-use tools to monitor your achievements.',
    ],
    programDetailPageUrl: {
      PREUAT: '/explore/diy/program/build-without-nurtrition--242',
      STAGE: '/explore/diy/program/build-33',
      PROD: '/explore/diy/program/build-36',
    },
  },
  SIX_PACK_PROJECT: {
    challengeID: {
      PREUAT: 197,
      STAGE: 19,
      PROD: 14,
    },
    seqNo: {
      PREUAT: 197,
      STAGE: 0,
      PROD: 34,
    },
    // bestPlanCardPoints: [
    //   'Transformative Workouts: Tailored routines targeting every muscle group.',
    //   'Supportive Community: Join like-minded individuals on this empowering journey.',
    //   'Track Your Progress: Easy-to-use tools to monitor your achievements.',
    // ],
    // programDetailPageUrl: {
    //   PREUAT: '/explore/diy/program/build-without-nurtrition--242',
    //   STAGE: '/explore/diy/program/build-33',
    //   PROD: '/explore/diy/program/build-36',
    // },
  },
  WEIGHT_AND_WATCH: {
    challengeID: {
      PREUAT: 184,
      STAGE: 13,
      PROD: 8,
    },
    seqNo: {
      PREUAT: 180,
      STAGE: 0,
      PROD: 28,
    },
    // bestPlanCardPoints: [
    //   'Transformative Workouts: Tailored routines targeting every muscle group.',
    //   'Supportive Community: Join like-minded individuals on this empowering journey.',
    //   'Track Your Progress: Easy-to-use tools to monitor your achievements.',
    // ],
    // programDetailPageUrl: {
    //   PREUAT: '/explore/diy/program/build-without-nurtrition--242',
    //   STAGE: '/explore/diy/program/build-33',
    //   PROD: '/explore/diy/program/build-36',
    // },
  },
};
