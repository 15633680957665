import styles from "./ButtonLoader.module.css";

export const ButtonLoader = ({ color = "#fff" }) => {
  return (
    <div className={styles.ldsEllipsis}>
      <div style={{background: color}}></div>
      <div style={{background: color}}></div>
      <div style={{background: color}}></div>
      <div style={{background: color}}></div>
    </div>
  );
};
