import axios from 'axios';

import { SERVER_URL, APP_TOKEN } from 'config/env';
import { store } from 'store';

const instance = axios.create({
  baseURL: SERVER_URL,
});

const refreshToken = async () => {
  const storageData = JSON.parse(localStorage.getItem('persist:root'));
  const sessionToken = JSON.parse(storageData.session)?.token;
  let data;
  if (window?.ReactNativeWebView?.postMessage) {
    data = { token: sessionToken, app: true };
  } else {
    data = { token: sessionToken };
  }
  await instance
    .post('authentication/token', data)
    .then((response) => {
      store.dispatch({ type: 'session/updateToken', payload: { token: response.data.token } });
      localStorage.setItem('token', response.data.token);
    })
    .catch((error) => {
      if (error.response) {
        store.dispatch({ type: 'session/logout', payload: {} });
        localStorage.removeItem('token');
        //window.location.href = '/login';
      }
      //window.location.href = '/login';
      return Promise.reject(error);
    });
};

instance.defaults.headers.common.Accept = 'application/json';
instance.defaults.headers.common['app-token'] = APP_TOKEN;

instance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const localStoreToken = localStorage.getItem('token');
    if (state.session.isLoggedIn) {
      config.headers.token = state.session.token;
    } else {
      if (localStoreToken) {
        config.headers.token = localStoreToken;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  // (error) => {
  //   console.log(error.response);
  //   if (JSON.parse(JSON.stringify(error))?.response?.status === 401) {
  //     localStorage.removeItem('token');
  //     localStorage.removeItem('_expiredTime');
  //     window.location.href = '/login';
  //   }
  // }
  async function (error) {
    const originalRequest = error.config;
    const storageData = JSON.parse(localStorage.getItem('persist:root'));
    const sessionToken = JSON.parse(storageData.session)?.token;
    if (
      error.response.status === 401 &&
      originalRequest.url !== 'authentication/token' &&
      !originalRequest._retry &&
      ![null, undefined, ''].includes(sessionToken)
    ) {
      originalRequest._retry = true;
      await refreshToken();
      return instance(originalRequest);
    } else if (
      error.response.status === 401 &&
      originalRequest.url !== 'authentication/token' &&
      !originalRequest._retry &&
      [null, undefined, ''].includes(sessionToken)
    ) {
      store.dispatch({ type: 'session/logout', payload: {} });
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);

export default instance;
