import React, { Component } from 'react';
import ErrorScreens from 'screens/Public/Errorscreens';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Update state to indicate an error has occurred
    this.setState({ hasError: true });

    // You can log the error or send it to a logging service
    // console.log(error.message);
    // console.log(error);
    const stack = error.stack;
    // console.log('Error Message:' + error.message);
    // console.log('Error Type:' + error.name);

    if (stack) {
      const stackLines = stack.split('\n');
      // console.log(stackLines);
      stackLines.shift();
      stackLines.forEach((item) => {
        let trim = item.trim();
        trim = trim.slice(2);
        const errComponent = trim.split(' ')[1];
        const errPath = trim.split(' ')[2];
        // console.log('component:' + errComponent);
        //console.log('path:'+ errPath);
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // Render a fallback UI or an error message
      return <ErrorScreens paramid={5} />;
    }

    // If no error occurred, render the child components
    return this.props.children;
  }
}

export default ErrorBoundary;
