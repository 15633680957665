export const ExploreIcon = ({ variant = '' }) => {
  return (
    <>
      {variant === '' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18Z"
            stroke="#677572"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M20 20L16 16" stroke="#677572" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        </svg>
      )}
      {variant === 'hover' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18Z"
            stroke="#141C1A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M20 20L16 16" stroke="#141C1A" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        </svg>
      )}
      {variant === 'active' && (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.3496 18C15.2156 18 18.3496 14.866 18.3496 11C18.3496 7.13401 15.2156 4 11.3496 4C7.48362 4 4.34961 7.13401 4.34961 11C4.34961 14.866 7.48362 18 11.3496 18Z"
            stroke="#141C1A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M10.3497 10.2957L12.6454 8" stroke="#DF002C" strokeWidth="2" strokeLinecap="square" />
          <path d="M11.3497 14.1479L12.4976 13" stroke="#DF002C" strokeWidth="2" strokeLinecap="square" />
          <path
            d="M20.3496 20L16.3496 16"
            stroke="#141C1A"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
