import styles from './ReferralsModalStyle.module.css';
import { MODEL_TRANSITION_DELAY, CLIENT_URL } from 'config/env';
import { useSelector } from 'react-redux';
import { selectReferralCode } from 'store/reducers/session';
import { Button, Modal } from '@mui/material';
import copy from 'copy-to-clipboard';
import { BiDollarCircle } from 'components/Icons/BiDollarCircle';
import { TbClipboardText } from 'components/Icons/TbClipboardText';
import { BsWhatsapp } from 'components/Icons/BsWhatsapp';
import { BiLogoGmail } from 'components/Icons/BiLogoGmail';
import modelCloseIcon from 'themes/icons/ModelClose.png';
import Support from 'components/Support';
import OverlapImages from './OverlapImages';
import ReferralTutorial from './ReferralTutorial';

// custom styles
const customStyles = {
  clipboardBtnStyle: {
    marginRight: '5px',
    border: '1px solid #677572',
    color: '#67572',
  },

  whatsappBtnStyle: {
    border: '1px solid green',
    color: 'green',
  },

  mailBtnStyle: {
    border: '1px solid #677572',
    color: '#67572',
  },

  modal: {
    zIndex: '200',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(3px)',
  },
};
// ---custom styles---

const ReferralsModal = ({ isOpen, closeModal }) => {
  const referralCode = useSelector(selectReferralCode);

  function copyToClipboard() {
    if (referralCode === '') {
      Support.showError('Referral code not available');
      return;
    }

    const result = copy(referralCode);
    if (result) {
      Support.showSuccess('Copied to clipboard');
    } else {
      Support.showError('Copy to clipboard failed');
    }
  }

  function shareToWhatsapp() {
    if (referralCode === '') {
      Support.showError('Referral code not available');
      return;
    }

    const url = `https://wa.me/?text=${encodeURIComponent(
      `Hey! Are you ready to be the fittest version of you? I've got you covered! Join me and several others on the journey by using my referral code : ${referralCode} and start Hustling. ${CLIENT_URL}login?referral=${referralCode}`
    )}`;
    window.open(url, '_blank');
  }

  function shareToMail() {
    if (referralCode === '') {
      Support.showError('Referral code not available');
      return;
    }

    const subject = 'Hustle by RCB';
    const emailContent = `Hey! Are you ready to be the fittest version of you? I've got you covered! Join me and several others on the journey by using my referral code : ${referralCode} and start Hustling. ${CLIENT_URL}login?referral=${referralCode}`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailContent)}`;
    window.open(mailtoLink, '_blank');
  }

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={customStyles.modal}
      BackdropProps={{
        timeout: MODEL_TRANSITION_DELAY,
      }}
      closeAfterTransition
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <p>REFERRALS</p>
          <img onClick={closeModal} src={modelCloseIcon} alt="" />
        </div>
        <div className={styles.body}>
          <div className={styles.imageSection}>
            <OverlapImages />
          </div>

          <div className={styles.description}>
            <p>Refer and earn</p>
            <p>
              Refer your friends and earn 150 points per friend when they register. Earn an extra 300 points when they
              subscribe for a plan.
            </p>
          </div>

          <div className={styles.codeSection}>
            <div className={styles.shareBtn}>
              <Button style={customStyles.clipboardBtnStyle} className={styles.clipboardBtn} onClick={copyToClipboard}>
                <TbClipboardText color="#677572" size="20px" />
                <span className={styles.buttonText}>Copy to Clipboard</span>
              </Button>

              <Button style={customStyles.whatsappBtnStyle} className={styles.whatsappBtn} onClick={shareToWhatsapp}>
                <BsWhatsapp size="20px" color="green" />
                <span className={styles.buttonText}>Share to Whatsapp</span>
              </Button>
            </div>
            <div className={styles.shareBtn}>
              <Button style={customStyles.mailBtnStyle} className={styles.mailBtn} onClick={shareToMail}>
                <BiLogoGmail size="20px" color="#677572" />
                <span className={styles.buttonText}>Share to Mail</span>
              </Button>
            </div>
          </div>

          <div className={styles.questionSection}>
            <div className={styles.quAndAnContainer}>
              <div className={styles.quIcon}>
                <BiDollarCircle size="25px" color="#D9A946" />
              </div>
              <div className={styles.quAndAn}>
                <p>Wondering what you can do with points?</p>
                <p>
                  You can buy more sessions, nutrition plans and much more.{' '}
                  {/* <span className={styles.learn}>Learn more</span> */}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.tutorialSection}>
            <p>This is how referral works:</p>
            <ReferralTutorial />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReferralsModal;
