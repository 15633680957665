export const CommunityIcon = ({ variant = '' }) => {
  return (
    <>
      {variant === '' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 4H16V12V18C16 19.1046 16.8954 20 18 20V20H8C5.79086 20 4 18.2091 4 16V4Z"
            stroke="#677572"
            strokeWidth="1.5"
          />
          <path
            d="M20 12H16V18C16 19.1046 16.8954 20 18 20V20C19.1046 20 20 19.1046 20 18V12Z"
            stroke="#677572"
            strokeWidth="1.5"
          />
          <path d="M8 9H12" stroke="#677572" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
          <path d="M8 13H10" stroke="#677572" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        </svg>
      )}
      {variant === 'hover' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 4H16V12V18C16 19.1046 16.8954 20 18 20V20H8C5.79086 20 4 18.2091 4 16V4Z"
            stroke="#141C1A"
            strokeWidth="1.5"
          />
          <path
            d="M20 12H16V18C16 19.1046 16.8954 20 18 20V20C19.1046 20 20 19.1046 20 18V12Z"
            stroke="#141C1A"
            strokeWidth="1.5"
          />
          <path d="M8 9H12" stroke="#141C1A" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
          <path d="M8 13H10" stroke="#141C1A" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        </svg>
      )}
      {variant === 'active' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 4H16V12V18C16 19.1046 16.8954 20 18 20V20H8C5.79086 20 4 18.2091 4 16V4Z"
            stroke="#141C1A"
            strokeWidth="2"
          />
          <path
            d="M20 12H16V18C16 19.1046 16.8954 20 18 20V20C19.1046 20 20 19.1046 20 18V12Z"
            stroke="#141C1A"
            strokeWidth="2"
            strokeLinecap="square"
          />
          <path d="M8 9H12" stroke="#DF002C" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
          <path d="M8 13H10" stroke="#DF002C" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        </svg>
      )}
    </>
  );
};
