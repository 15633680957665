import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import {
  GOOGLE_ANALYTICS_KEY,
  FB_PIXEL_ID,
  CLTP_ID,
  SERVER_TYPE,
  RCB_SITE_URL,
} from 'config/env';
import clevertap from 'clevertap-web-sdk';
import { analytics } from './gtm';

export const initialize = () => {
  ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
  ReactGA.set({
    username: 'RCB Hustle',
  });
  if (SERVER_TYPE === 'PROD') {
    window.gtag('set', 'linker', {
      domains: [
        'trainee.hustle.royalchallengers.com',
        'royalchallengers.com/hustle',
        'www.royalchallengers.com/hustle',
        'live.hustle.royalchallengers.com',
      ],
    });
  }
  const options = {
    autoConfig: true,
  };
  ReactPixel.init(FB_PIXEL_ID, options);
  ReactPixel?.fbq('set', 'autoConfig', FB_PIXEL_ID, RCB_SITE_URL);

  clevertap.init(CLTP_ID, 'in1');
};

export const cleverTapEvent = (eventName, data) => {
  clevertap.event.push(eventName, data);
};
export const logScreenChange = () => {
  if (SERVER_TYPE === 'PROD') {
    analytics(window, document, 'script', 'dataLayer', 'GTM-NDBK8Q6L');
  }
  if (window?.dataLayer) {
    window?.dataLayer?.push({
      event: 'Pageview',
      pagePath: window.location.pathname + window.location.search,
      pageTitle: window.location.pathname,
    });
  }
  ReactGA.pageview(window.location.pathname + window.location.search);
  //ReactPixel.pageView();
  cleverTapEvent('Page Viewed', {
    Page_Viewed:
      'RCB:Hustle:' + window.location.pathname + window.location.search,
  });
  if (SERVER_TYPE === 'PROD') {
    window.gtag('event', 'page_view', {
      send_to: 'AW-11085364607',
      value: window.location.pathname + window.location.search,
      items: [
        {
          google_business_vertical: 'custom',
        },
      ],
    });
  }
};

export const logEvent = (data) => {
  ReactGA.event(data);
};

export const signedUser = (userID) => {
  ReactGA.set({ userId: userID });
};

export const cleverTapOnUserLogin = (data) => {
  if (SERVER_TYPE !== 'PROD') {
    clevertap.privacy.push({ optOut: false });
    clevertap.privacy.push({ useIP: true });
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/clevertap_sw.js', { scope: '/' })
          .then((registration) => {
            console.log(
              'Service Worker registered with scope:',
              registration.scope,
            );
          })
          .catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      });
    }

    clevertap.notifications.push({
      apnsWebPushId: '<apple web push id>',
      apnsWebPushServiceUrl: '<safari package service url>',
      titleText: 'Would you like to receive Push Notifications?',
      bodyText: 'We only send relevant content',
      okButtonText: 'Sign me up!',
      rejectButtonText: 'No thanks',
      okButtonColor: '#F28046',
      askAgainTimeInSeconds: 5,
      serviceWorkerPath: './clevertap_sw.js',
    });
  }
  clevertap.onUserLogin.push({
    Site: data,
  });
};

export const cleverTapProfile = (data) => {
  clevertap.profile.push({
    Site: data,
  });
};

export const customDimension = (dimension) => {
  ReactGA.set(dimension);
};

export const fbStandardEvent = (event, data) => {
  ReactPixel.track(event, data);
};

export const fbCustomEvent = (event, data) => {
  ReactPixel.trackCustom(event, data);
};

export const gaDataLayer = (data) => {
  // console.log(data, 'dataLayer');
  window?.dataLayer?.push(data);
};
