import { Button } from '@mui/material';
import { ButtonLoader } from 'components/loaders/ButtonLoader';

export const PrimaryButton = ({
  text,
  height,
  onClick,
  fullwidth = false,
  showLoader = false,
  disabled,
  backgroundColor = 'var(--primaryColor)',
  color = 'white',
  fontSize = '10px',
}) => {
  return (
    <Button
      fullWidth={fullwidth}
      sx={{
        borderRadius: '0px',
        color: color,
        fontWeight: 600,
        height: height,
        fontSize: fontSize,
        fontFamily: 'var(--secondaryRegular)',
        backgroundColor: backgroundColor,
        ':hover': {
          backgroundColor: backgroundColor,
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {showLoader ? <ButtonLoader color="white" /> : text}
    </Button>
  );
};
