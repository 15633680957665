import { ButtonLoader } from "./ButtonLoader";
import "./root-loader.css";

export function RootLoader() {
    // return <h1 className="rootLoader">HUSTLE</h1>
    return <div className="root-loader">
        <div className="root-loader-container">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div className="root-loader-text">
            <ButtonLoader color="#DF002C"/>
      </div>
    </div>
}