export const ReferralsIcon = ({ size = "1rem", color = "", hover = false, hoverColor= "" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill='none'
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.808 15.9995V15.6615C12.808 14.4782 11.7622 13.5678 10.5902 13.7308C8.80797 13.9788 7.00005 13.9788 5.21787 13.7308C4.04581 13.5678 3 14.4782 3 15.6615V15.9995"
        stroke={hover ? hoverColor: color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M7.90498 11.5052C9.9775 11.5052 11.6576 9.82516 11.6576 7.75264C11.6576 5.68011 9.9775 4 7.90498 4C5.83246 4 4.15234 5.68011 4.15234 7.75264C4.15234 9.82516 5.83246 11.5052 7.90498 11.5052Z"
        stroke={hover ? hoverColor: color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M17.5009 16.0009V15.6942C17.5009 14.5902 16.6059 13.6953 15.502 13.6953"
        stroke={hover ? hoverColor: color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M14.1562 11.1052C15.9167 11.1052 17.3436 9.67818 17.3436 7.91784C17.3436 6.1575 15.9167 4.73047 14.1562 4.73047"
        stroke={hover ? hoverColor: color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};
