import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';

import { refineChatUser } from 'helper/chat';

const initialState = {
  connected: false,
  userIds: {},
  messages: {},
  opened: {},
};

const callbacks = {};

callbacks.onConnect = (state) => {
  state.connected = true;
};

callbacks.onDisconnect = (state) => {
  state.connected = false;
};

callbacks.openChat = (state, action) => {
  if (state.opened[action.payload.id]) {
    state.opened[action.payload.id].focusedAt = moment().format('x');
  } else {
    state.opened[action.payload.id] = {
      id: action.payload.id,
      focusedAt: moment().format('x'),
    };
  }
};

callbacks.closeChat = (state, action) => {
  if (state.opened[action.payload.id]) {
    delete state.opened[action.payload.id];
  }
};

callbacks.toggleMinimizeChat = (state, action) => {
  if (state.opened[action.payload.id]) {
    state.opened[action.payload.id].minimize = state.opened[action.payload.id].minimize ? 0 : 1;
  }
};

callbacks.updateUser = (state, { payload: { id, chatStatus, profile } }) => {
  const data = refineChatUser({ userID: id, ...profile, chatStatus });
  const index = state.userIds[id];
  if (index === undefined) {
    if (data.id && data.name) {
      state.userIds[id] = data;
    }
  } else {
    state.userIds[id] = { ..._.cloneDeep(state.userIds[id]), ...data };
  }
};

callbacks.updateUsers = (state, { payload: { users } }) => {
  const ids = [];
  users.forEach((user) => {
    const data = refineChatUser(user);
    const index = state.userIds[user.userID];
    if (index === undefined) {
      state.userIds[user.userID] = data;
    } else {
      state.userIds[user.userID] = { ..._.cloneDeep(state.userIds[user.userID]), ...data };
    }
    ids.push(user.userID);
  });
};

callbacks.removeUser = (state, { payload: { id } }) => {
  const index = state.userIds[id];
  /* eslint-disable-next-line */
  if (index === undefined) {
  } else {
    delete state.userIds[id];
  }
};

callbacks.resetMessages = (state, { payload: { userID } }) => {
  state.messages[userID] = [];
  const index = state.userIds[userID];
  if (index === undefined) {
    return;
  }
  state.userIds[userID].firstTs = null;
};

callbacks.addMessages = (state, { payload: { messages, userID } }) => {
  messages.forEach((message) => {
    const ts = moment(message.timestamp, 'x');
    const day = ts.startOf('day').format('x');
    const oUserID = message.from == userID ? message.to : message.from;

    const index = state.userIds[oUserID];
    /* eslint-disable-next-line */
    if (index === undefined) {
    } else {
      if (!state.userIds[oUserID].firstTs || message.timestamp < state.userIds[oUserID].firstTs) {
        state.userIds[oUserID].firstTs = message.timestamp;
      }
    }

    let type = 'text';
    if (message.payload.CDNFile) {
      type = 'file';
    }

    const m = {
      id: message.id,
      from: message.from,
      type,
      text: message.payload.text,
      file: message.payload.CDNFile,
      timestamp: message.timestamp,
      day,
    };

    if (typeof state.messages[oUserID] === 'undefined') {
      state.messages[oUserID] = [];
    }
    if (state.messages[oUserID].findIndex((r) => r.id == m.id) == -1) {
      state.messages[oUserID].push(m);
      state.messages[oUserID].sort((a, b) => a.timestamp - b.timestamp);
    }
  });
};

callbacks.addLastMessage = (state, { payload: { message, userID } }) => {
  if (message.from != userID) {
    const oUserID = message.from;

    const index = state.userIds[oUserID];
    if (index === undefined) {
      return;
    }

    state.userIds[oUserID].lastMsgTimeStamp = message.timestamp;
    state.userIds[oUserID].messageCount = (state.userIds[oUserID].messageCount || 0) + 1;
    if (state.opened[oUserID] && !state.opened[oUserID].minimize) {
      state.userIds[oUserID].readMessageCount = state.userIds[oUserID].messageCount;
    }
    state.userIds[oUserID].lastMsgTeaser = message.payload.CDNFile ? 'Image' : message.payload.text;
  }
};

callbacks.reset = () => {
  return _.cloneDeep(initialState);
};

const slice = createSlice({
  name: 'chat',
  initialState: _.cloneDeep(initialState),
  reducers: callbacks,
});

const { actions, reducer } = slice;

export const {
  onConnect,
  onDisconnect,

  openChat,
  closeChat,
  toggleMinimizeChat,

  updateUser,
  updateUsers,
  removeUser,
  resetMessages,
  addMessages,
  addLastMessage,

  updateGroup,
  updateGroups,
  acceptedGroupInvite,
  rejectedGroupInvite,
  removedFromGroup,
  addGroupMessages,
  addLastGroupMessages,
  resetGroupMessages,
  updateGroupPreference,

  reset,
} = actions;

export default reducer;
