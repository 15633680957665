import React from 'react';
import packageJson from '../package.json';

const globalAppVersion = packageJson.version;
const isProduction = process.env.NODE_ENV !== 'development';
// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLatestVersion: false,
      visibilityState: false,
      currentVisibilityState: null,
    };
    this.checkAppVersion = this.checkAppVersion.bind(this);
    this.refreshCacheAndReload = this.refreshCacheAndReload.bind(this);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.checkAppVersion();
      }
    });
    setInterval(() => {
      const locationChanging = localStorage.getItem('locationChanging') || false;
      if (JSON.parse(locationChanging)) {
        this.checkAppVersion();
      }
    }, 1000);
  }

  checkAppVersion() {
    if (isProduction) {
      localStorage.removeItem('locationChanging');
      fetch(`/meta.json?v=${globalAppVersion}`)
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          const currentVersion = globalAppVersion;

          const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
          if (shouldForceRefresh) {
            // console.log(`We have a new version - ${latestVersion}. Should force refresh`);
            this.setState({ loading: false, isLatestVersion: false });
            this.refreshCacheAndReload();
          } else {
            // console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
            this.setState({ loading: false, isLatestVersion: true });
          }
        });
    }
  }

  refreshCacheAndReload() {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    localStorage.removeItem('locationChanging');
    window.location.reload(true);
  }

  // refreshCache() {
  //   if (caches) {
  //     // Service worker cache should be cleared with caches.delete()
  //     caches.keys().then(function (names) {
  //       for (let name of names) caches.delete(name);
  //     });
  //   }
  // }

  render() {
    return <></>;
  }
}

export default CacheBuster;
