import styles from "./OverlapImagesStyle.module.css";
import pic1 from "themes/images/referrals/ReferDemoPic1.png";
import pic2 from "themes/images/referrals/ReferDemoPic2.png";
import pic3 from "themes/images/referrals/ReferDemoPic3.png";

const OverlapImages = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imgWrap1}>
        <img src={pic1} alt="" />
      </div>
      <div className={styles.imgWrap2}>
        <img src={pic2} alt="" />
      </div>
      <div className={styles.imgWrap3}>
        <img src={pic3} alt="" />
      </div>
    </div>
  );
};

export default OverlapImages;
